import { useEffect, useState } from 'react';

import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
// Table Imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from '@mui/material/CircularProgress';
import ListofNotifications from './ListofNotifications';
import ScheduleInputNotification from './ScheduleInputNotification';
import { callAxios } from '../../../utils/axios';
import { styled } from "@mui/material/styles";
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from "../../../store/hooks";
import dayjs from "dayjs";
import AddNotification from './AddNotification';
import EditNotification from './EditNotification';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState("");
  const [searchText, setSearchText] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [addNotificationModal, setAddNotificationModal] = useState(false);
  const [editNotificationModal, setEditNotificationModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const { user } = useAppSelector((state) => state?.user);
  const [status, setStatus] = useState("All");

  const getAllNotification = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/notifications/getNotifications?page=${page}&limit=10&search=${searchText}`
      );
      console.log("data: ", data);

      setNotifications(data.data);

      setTotalPages(data.totalPages);
      setDataRange(
        "Showing" +
        (data?.currentPage * 10 - 9) +
        "-" +
        data?.currentPage * 10 +
        " of " +
        data?.totalData
      );
      //   setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  const cancelNotification = async (id) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/notifications/updateNotification?id=${id}`,
        { cancel: true }
      );
      console.log(data);
      toastMessage("Notification Cancelled Successfully", "success");
      // setNotifications((prev) =>
      //   prev.map((el) => {
      //     if (el._id == id) return data?.data;
      //     return el;
      //   })
      // );
      setNotifications((prev) =>
        prev.filter((notification) => notification._id !== id)
      );
      setLoading(false);

    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllNotification();
  }, [page, searchText]);
  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Notification
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Manage Notification
          </p>
        </div>
        <div className="w-full flex flex-row justify-between gap-5 bg-white rounded-lg shadow p-4 px-8 ">
          <div className="w-2/3 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          {/* <div className='flex justify-around'>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
            >
              <option value="" className="text-gray-400" disabled>
                Status
              </option>
              <option value="All" className="text-gray-700">
                All
              </option>
              <option value="true" className="text-gray-700">
                Enable
              </option>
              <option value="false" className="text-gray-700">
                Disable
              </option>
            </select>
          </div> */}
          {
            user?.userType === "ADMIN" &&
            <div className="flex  justify-center items-center">
              <button
                className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"
                onClick={() => setAddNotificationModal(true)}
              >
                Add Notification
              </button>
            </div>
          }
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  {/* <CustomTableHeadCell></CustomTableHeadCell> */}
                  <CustomTableHeadCell>Subject</CustomTableHeadCell>
                  <CustomTableHeadCell>Announcement</CustomTableHeadCell>
                  <CustomTableHeadCell>Type</CustomTableHeadCell>
                  <CustomTableHeadCell>Scheduled DateTime</CustomTableHeadCell>
                  <CustomTableHeadCell>Sent</CustomTableHeadCell>
                  {/* <CustomTableHeadCell>Read</CustomTableHeadCell> */}
                  {/* <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell> */}
                  <CustomTableHeadCell>
                    <div className="flex justify-center">Action</div>
                  </CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {notifications.map((notification: any) => {
                  return (
                    <CustomTableBodyRow key={notification._id}>
                      {/* <CustomTableBodyCell>
                        <img
                          src={
                            notification?.images?.length > 0
                              ? notification?.images[0]
                              : "/assets/images/user-logo.png"
                          }
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                      </CustomTableBodyCell> */}
                      <CustomTableBodyCell className="w-1/5">
                        {notification.title.length > 130 ? notification.title.substring(0, 130) + "..." : notification.title}
                        {/* {notification.title} */}
                      </CustomTableBodyCell>
                      {/* <CustomTableBodyCell className="w-36"> */}
                      <CustomTableBodyCell className="w-1/4">
                        {notification.description.length > 130 ? notification.description.substring(0, 130) + "..." : notification.description}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {notification.event}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {dayjs(notification.scheduleDateTime).isValid() ? dayjs(notification.scheduleDateTime).format('DD-MM-YYYY hh:mm A') : ''}
                        {/* {notification.scheduleDateTime} */}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {notification.sent ? "Yes" : "No"}
                        {/* {String(notification?.sent)} */}
                        {/* {notification?.sent?.toString()} */}
                        {/* {notification?.sent?.toString() ?? "false"} */}
                      </CustomTableBodyCell>
                      {/* <CustomTableBodyCell>
                        {notification.read}
                      </CustomTableBodyCell> */}
                      <CustomTableBodyCell>
                        <div className='flex justify-center items-center gap-5'>
                          <button
                            onClick={() => {
                              setSelectedNotification(notification);
                              setEditNotificationModal(true);
                            }}
                            className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              cancelNotification(notification._id);
                            }}
                            className="text-white publicSans text-sm font-medium leading-5 bg-red-500 py-1 px-2 rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
        <Modal
          open={addNotificationModal}
          onClose={() => setAddNotificationModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <AddNotification
            setOpen={setAddNotificationModal}
            setNotifications={setNotifications}
          />
        </Modal>
        <Modal
          open={editNotificationModal}
          onClose={() => setEditNotificationModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <EditNotification
            setOpen={setEditNotificationModal}
            setNotifications={setNotifications}
            selectedNotification={selectedNotification}
          />
        </Modal>
        {/* <div className="flex gap-4">
          <div className="w-2/5 h-72 flex flex-col py-2 gap-2 px-2 ">
            <ScheduleInputNotification
              setNotifications={setNotifications}
            />
          </div>
          <div className="w-3/5 flex flex-col py-2 gap-2 px-2">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <ListofNotifications
                notifications={notifications}
                setNotifications={setNotifications}
              />
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Notifications;

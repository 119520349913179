/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';

import AssignDriver from './Common/AssignDriver';
import Loading from 'react-fullscreen-loading';
import { Modal } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';

// Table Imports
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const TaxiBookingTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [taxiBookings, setTaxiBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [createNewDriverOpen, setCreateNewDriverOpen] = useState(false);

  const getAllTaxiBookings = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/taxiBooking/getBookings?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);

      setTaxiBookings(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPages);
      setDataRange(
        'Showing' +
          (data?.currentPage * 10 - 9) +
          '-' +
          data?.currentPage * 10 +
          ' of ' +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getAllTaxiBookings();
  }, [page, searchText]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Taxi Management
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Taxi Management
          </p>
        </div>
        <div className="w-full bg-white rounded-lg flex justify-between items-center shadow p-4 px-8 ">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: 'separate !important',
                width: '100%',
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Appointment Date</CustomTableHeadCell>
                  <CustomTableHeadCell>Pickup Time</CustomTableHeadCell>
                  <CustomTableHeadCell>Pickup Location</CustomTableHeadCell>
                  <CustomTableHeadCell>Drop Time</CustomTableHeadCell>
                  <CustomTableHeadCell>Drop Location</CustomTableHeadCell>
                  <CustomTableHeadCell>Status</CustomTableHeadCell>
                  <CustomTableHeadCell>Driver</CustomTableHeadCell>
                  <CustomTableHeadCell>Action</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {taxiBookings.map((booking: any) => {
                  let dateString = `${new Date(booking?.appointmentTimesamp).getDate()}/${new Date(booking?.appointmentTimesamp).getMonth() + 1}/${new Date(booking?.appointmentTimesamp).getFullYear()}`
                  return (
                    <CustomTableBodyRow key={booking?._id}>

                      <CustomTableBodyCell>
                        {Date.parse(booking?.appointmentTimesamp)? dateString : ''}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {booking?.startTime}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <a className='underline' href={`https://www.google.com/maps/@${booking?.pickupLocation.coordinates[1]},${booking?.pickupLocation.coordinates[0]},17z`} target="_blank" rel="noreferrer">
                        {`(${booking?.pickupLocation.coordinates.join(', ')})`}
                        </a>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        {booking?.endTime}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <a className='underline' href={`https://www.google.com/maps/@${booking?.dropoffLocation.coordinates[1]},${booking?.dropoffLocation.coordinates[0]},17z`} target="_blank" rel="noreferrer">
                        {`(${booking?.dropoffLocation.coordinates.join(', ')})`}
                        </a>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        {booking?.status}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <a href={`/driver/details?id=${booking?.driverId}`}>
                          {booking?.driverId?.username ?? "None Assigned"}
                        </a>
                      </CustomTableBodyCell>
                      {(booking?.driverId == null) &&
                      <CustomTableBodyCell>
                        <button
                          onClick={() => {
                            setCreateNewDriverOpen(true);
                            setSelectedBooking(booking);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                        >
                          Assign Driver
                        </button>
                      </CustomTableBodyCell>}
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>

      <Modal
        open={createNewDriverOpen}
        onClose={() => setCreateNewDriverOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssignDriver 
        booking={selectedBooking}
        setOpen={(value)=> {
          setCreateNewDriverOpen(value)
          getAllTaxiBookings()
        }}
        />
      </Modal>
    </>
  );
};

export default TaxiBookingTable;

import { Button, TextField } from '@mui/material';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { CropperModal } from '../../Common/ImageCropper';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';

type FormProps = {
  name: string;
  icon: string;
};
const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

function AddCategory({ setOpen, setCategories }) {
  const defaultValues: FormProps = {
    name: '',
    icon: '',
  };
  const [loading, setLoading] = useState(false);

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (!image) {
      return toastMessage('Please upload a profile picture', 'error');
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', image);
      const { data: result } = await callAxios(
        'post',
        'app/user/uploadImage',
        formData
      );

      const payload = {
        ...values,
        icon: result.url,
      };

      const { data } = await callAxios(
        'post',
        'dashboard/products/createCategory',
        payload
      );
      console.log(data);
      setLoading(false);
      setOpen(false);
      setCategories((prev) => [data.data, ...prev]);
      toastMessage('Product Category Created Successfully', 'success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };
  return (
    <>
      <Box sx={style}>
        <Box sx={style}>
          <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
          <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
            <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
              <img
                src="/assets/icons/cross.svg"
                alt="close"
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
              Add Category
            </h5>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-1 ">
                  <div className="w-full flex flex-col gap-1">
                    <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                      Category Name
                    </p>
                    <TextFieldElement
                      name="name"
                      // inputProps={{ maxLength: 10 }}
                      fullWidth
                      size="small"
                      placeholder="Enter Category Name"
                      inputMode="text"
                      sx={styles.root}
                      required
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-1 ">
                  <h6 className="text-tertiary publicSans text-base font-semibold ">
                    Product Category Picture / Category Logo
                  </h6>
                  <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                    <div className="flex flex-col gap-4 items-center">
                      <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                        <img
                          src={preview ?? '/assets/icons/picture.png'}
                          alt="icon"
                          className="w-4 h-4"
                        />
                      </div>
                      <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                        Drag and drop image here, or click add image
                      </p>
                      <Button
                        className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                        component="label"
                        variant="contained"
                        color="secondary"
                      >
                        Add Image
                        <VisuallyHiddenInput
                          type="file"
                          required
                          accept="image/*"
                          onChange={handleImgChange}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-end pt-4">
                  <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                    Add Category
                  </button>
                </div>
              </div>
            </FormContainer>
          </div>
        </Box>
      </Box>
      <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      />
    </>
  );
}

export default AddCategory;

import {
  DatePickerElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useForm, FormProvider } from "react-hook-form";
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { CropperModal } from '../../Common/ImageCropper';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';

type FormProps = {
  name: string;
  description: string;
  // startDate: Date;
  // endDate: Date;
  // startDate: string;
  // endDate: string;
  startDate: dayjs.Dayjs | null; // dayjs object or null
  endDate: dayjs.Dayjs | null;
  images: string[];
};
const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
  deleteButton: {
    position: 'absolute' as 'absolute',
    top: '-8px', // Adjust the position slightly to make it fit better with the image
    right: '-8px',
    backgroundColor: '#FF5C5C', // Red background for delete action
    color: '#fff', // White icon color
    padding: '4px', // Adjust padding for smaller button
    borderRadius: '50%', // Ensures a circular shape
    cursor: 'pointer',
    border: 'none',
    fontSize: '12px', // Smaller size for extra small
    width: '20px', // Adjust width of the button
    height: '20px', // Adjust height of the button
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',  // Soft shadow to give it depth
    transition: 'background-color 0.3s ease, transform 0.2s', // Smooth transitions
  },
  deleteButtonHover: {
    backgroundColor: '#FF3B3B',  // Slightly darker red on hover
    transform: 'scale(1.1)', // Slightly enlarge the button on hover for interactivity
  },
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

function EditAdvertisement({
  setOpen,
  setAdvertisements,
  selectedAdvertisement,
  setSelectedAdvertisement,
}) {

  // console.log("selectedAdvertisement?.startDate: ", selectedAdvertisement?.startDate);
  // console.log("selectedAdvertisement?.endDate: ", selectedAdvertisement?.endDate);

  const defaultValues: FormProps = {
    name: selectedAdvertisement?.name,
    description: selectedAdvertisement?.description,
    // startDate: dayjs(selectedAdvertisement?.startDate),
    startDate: selectedAdvertisement?.startDate, // Date are already passed to this component as Dayjs objects
    endDate: selectedAdvertisement?.endDate,
    images: selectedAdvertisement?.images,
  };
  const [loading, setLoading] = useState(false);

  // const [src, setSrc] = useState(null);
  // const [preview, setPreview] = useState(null);
  // const [image, setImage] = useState('');
  // const [modalOpen, setModalOpen] = useState(false);
  const [preview, setPreview] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(selectedAdvertisement?.startDate);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(selectedAdvertisement?.endDate);

  const methods = useForm<FormProps>({ defaultValues });
  const { watch, control } = methods;
  const watchedStartDate = watch("startDate"); // watching the startDate field

  const [error, setError] = useState<string>("");

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'minDate': {
        console.log("validation error: ", error);
        return toastMessage('End Date should be after or same as Start Date', 'error');
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [error]);



  // handle Change
  // const handleImgChange = (e) => {
  //   setSrc(URL.createObjectURL(e.target.files[0]));
  //   setModalOpen(true);
  // };

    // Handle file change (for new image upload)
    const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files as FileList); // Get selected files
      const newFiles = files.map((file) => URL.createObjectURL(file)); // Create object URLs for previews
  
      // If multiple images are selected, update both the preview and imageFiles arrays
      setImageFiles((prevFiles) => [...prevFiles, ...files]); // Append the new files
      setPreview((prevPreview) => [...prevPreview, ...newFiles]); // Append new image previews
  
      // Open modal or show preview after file selection
      // setModalOpen(true);
    };
  
    // Delete a specific image
    const deleteImage = (index: number) => {
      setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove from image files state
      setPreview((prevPreview) => prevPreview.filter((_, i) => i !== index)); // Remove from preview list
    };

  const onSubmit = async (values: FormProps) => {

    console.log("values Before updating Dates", values);

    values = { ...values, startDate: startDate, endDate: endDate };

    console.log("values After updating Dates: ", values);

    if (startDate.isAfter(endDate, "day")) {
      // console.log(`startDate.isAfter(endDate, "day"): `, startDate.isAfter(endDate, "day"));      
      return toastMessage('End Date should be after or same as Start Date', 'error');
    }

    // return;

    // if (!image) {
    //   return toastMessage("Please upload a profile picture", "error");
    // }
    try {
      setLoading(true);

      // let result;

      // if (image) {
      //   const formData = new FormData();
      //   formData.append('file', image);
      //   result = await callAxios('post', 'app/user/uploadImage', formData);
      //   console.log(result);
      // }

      let imageUploadPromises = [];

      // Upload new images
      if (imageFiles.length) {

        imageUploadPromises = imageFiles.map(async (file: File) => {
          const formData = new FormData();
          formData.append('file', file);
          const { data: result } = await callAxios('post', 'app/user/uploadImage', formData);
          return result.url;
        });
      }

      const imageUrls = await Promise.all(imageUploadPromises);

      const payload = {
        ...values,
        // images: image ? [result?.data?.url] : selectedAdvertisement?.images,
        images: imageUrls.length ? [...selectedAdvertisement?.images, ...imageUrls] : selectedAdvertisement?.images,
      };
      const { data } = await callAxios(
        'put',
        `dashboard/advertisements/updateAdvertisement/${selectedAdvertisement?._id}`,
        payload
      );
      console.log(data);
      setLoading(false);
      setOpen(false);
      setAdvertisements((prev) =>
        prev.map((el) => {
          if (el?._id == data.data?._id) return data.data;
          else return el;
        })
      );
      toastMessage('Advertisement Advertisement Updated Successfully', 'success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };
  return (
    // <Box sx={style}>
    <Box sx={style} className="overflow-y-auto h-full" >
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
          Edit Advertisement
        </h5>
        <FormProvider {...methods}>

          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex flex-col gap-1 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Advertisement Name
                  </p>
                  <TextFieldElement
                    name="name"
                    fullWidth
                    size="small"
                    placeholder="Enter Advertisement Name"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col gap-1 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Advertisement Description
                  </p>
                  <TextFieldElement
                    name="description"
                    fullWidth
                    size="small"
                    placeholder="Enter Advertisement description"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement Start Date</p>
                <DatePickerElement
                  onChange={(e) => {
                    // console.log("startDate: ", e["$d"]);
                    // setStartDate(e["$d"]);
                    setStartDate(e);
                  }}

                  control={control}
                  name="startDate"
                  sx={styles.root}
                  inputProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  // disablePast={true} // Note: will give validation error if the user does not change the Date (which
                  // could be of Past Month or Older) when updating the Advertisement, so let it be like that.
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement End Date</p>
                <DatePickerElement
                  onChange={(e) => {
                    // console.log("endDate: ", e["$d"]);
                    // setEndDate(e["$d"]);
                    setEndDate(e);
                  }}

                  control={control}
                  name="endDate"
                  sx={styles.root}
                  inputProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  // disablePast={true}
                  minDate={watchedStartDate && dayjs(watchedStartDate)}
                  onError={(newError) => setError(newError)}  // showing Toast instead of inline Validation Error Messages
                  required
                />
              </div>
              {/* <div className="w-full flex flex-col gap-1 ">
                <h6 className="text-tertiary publicSans text-base font-semibold ">
                  Advertisement Pictures
                </h6>
                <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                  <div className="flex flex-col gap-4 items-center">
                    <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                      <img
                        src={
                          preview
                            ? preview
                            : selectedAdvertisement?.images?.length > 0
                              ? selectedAdvertisement?.images[0]
                              : '/assets/icons/picture.png'
                        }
                        alt="icon"
                        className="w-4 h-4"
                      />
                    </div>
                    <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                      Drag and drop image here, or click add image
                    </p>
                    <Button
                      className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                      component="label"
                      variant="contained"
                      color="secondary"
                    >
                      Add Image
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/*"
                        onChange={handleImgChange}
                      />
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className="w-full flex flex-col gap-1">
              <h6 className="text-tertiary publicSans text-base font-semibold">
              Advertisement Pictures
              </h6>
              <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center w-full">
                  <div className="rounded-lg p-3 w-full">
                    {preview.length > 0 ? (
                      <div className="grid grid-cols-4 gap-4">
                        {preview.map((img, index) => (
                          <div key={index} className="relative text-center bg-[#E8F6FF] p-3 rounded-lg shadow-md hover:shadow-lg transition-all">
                            {/* The background color here is distinct for each image */}
                            <img
                              src={img}
                              alt={`preview-${index}`}
                              className="w-16 h-16 object-cover mx-auto" // Centering the image
                            />
                            <p className="text-xs mt-2">{imageFiles[index]?.name}</p>
                            <button
                              type='button'
                              onClick={() => deleteImage(index)}
                              style={styles.deleteButton} // Apply the styles here
                              className="hover:bg-red-600 hover:scale-110" // Add hover effect classes from tailwind or use inline styles as well
                            >
                              <CloseIcon style={{ color: '#fff', fontSize: '12px' }} /> {/* MUI Close icon */}
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <img src="/assets/icons/picture.png" alt="icon" className="w-4 h-4 mx-auto" />
                    )}
                  </div>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5"
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Images
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      onChange={handleImgChange}
                      multiple
                    />
                  </Button>

                </div>
              </div>
            </div>
              <div className="w-full flex justify-end pt-4">
                <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                  Edit Advertisement
                </button>
              </div>
            </div>
          </FormContainer>

        </FormProvider>

      </div>
      {/* <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      /> */}
    </Box>
    // </Box>
  );
}

export default EditAdvertisement;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';

import CreateDriver from './Common/CreateDriver';
import Loading from 'react-fullscreen-loading';
import { Modal } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';

// Table Imports
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const DriversTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [createNewDriverOpen, setCreateNewDriverOpen] = useState(false);

  const getAllDrivers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/driver/getAllDrivers?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);

      setDrivers(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        'Showing' +
          (data?.currentPage * 10 - 9) +
          '-' +
          data?.currentPage * 10 +
          ' of ' +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const handleEnableDisable = async (event, id: any) => {
    console.log(event.target.checked, id);

    try {
      setLoading(true);
      const { data } = await callAxios(
        'put',
        `dashboard/driver/updateDriver?id=${id}`,
        {
          active: event.target.checked,
        }
      );
      toastMessage(
        `Driver ${event.target.checked ? 'Disabled' : 'Enabled'} successfully`,
        'success'
      );
      getAllDrivers();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getAllDrivers();
  }, [page, searchText]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Driver Management
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Driver Management
          </p>
        </div>
        <div className="w-full bg-white rounded-lg flex justify-between items-center shadow p-4 px-8 ">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {/* Create New Driver */}
          <div className="flex items-center gap-2 bg-secondary p-2 px-4 rounded-full">
            <button
              className="text-sm text-primary font-semibold hover:text-primary-light"
              onClick={() => {
                setCreateNewDriverOpen(true);
              }}
            >
              Create New Driver
            </button>
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: 'separate !important',
                width: '100%',
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell></CustomTableHeadCell>
                  <CustomTableHeadCell>Full Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Email</CustomTableHeadCell>
                  <CustomTableHeadCell>Vehicle No</CustomTableHeadCell>
                  <CustomTableHeadCell>Phone No</CustomTableHeadCell>
                  <CustomTableHeadCell>Action</CustomTableHeadCell>
                  <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {drivers.map((user: any) => {
                  return (
                    <CustomTableBodyRow key={user?._id}>
                      <CustomTableBodyCell>
                        <img
                          src={
                            user?.profilePicture
                              ? user.profilePicture
                              : '/assets/images/user-logo.png'
                          }
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.username}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{user?.email}</CustomTableBodyCell>
                      <CustomTableBodyCell>{`${user?.vehicleNumber}`}</CustomTableBodyCell>
                      <CustomTableBodyCell>{`(${user?.countryCode}) ${user?.phone}`}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <button
                          onClick={() =>
                            navigate(
                              `/admin/user-details?userId=${user?._id}&userType=DRIVER`
                            )
                          }
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                        >
                          Details
                        </button>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <div className="flex justify-center">
                          <Switch
                            checked={user?.active}
                            onChange={(e) => handleEnableDisable(e, user?._id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                          />
                        </div>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>

      <Modal
        open={createNewDriverOpen}
        onClose={() => setCreateNewDriverOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateDriver setOpen={(value)=> {
          setCreateNewDriverOpen(value)
          getAllDrivers()
        }} />
      </Modal>
    </>
  );
};

export default DriversTable;

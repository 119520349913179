import axios from "axios";
import { toastMessage } from "./toast";

// "Local" Backend URL
// export const backendUrl = "http://localhost:9000/api";

// "Test" Backend URL
// export const backendUrl = `https://petapi.bls.com.pk/api`;

// "Live" Backend URL
// export const backendUrl = 'https://api.petrepublic.ae/api';

// export const backendUrl = `${process.env.REACT_APP_BACK_END_URL}/api`;

// export const backendUrl = "https://pet-republic.applore.in/api";
// export const backendUrl = "https://5czpmmsq-3000.inc1.devtunnels.ms/api";
export const backendUrl = "https://api.petrepublic.ae/api";
// export const backendUrl = "";

const checkStatus = (response) => {
  if (response?.status === 200 || response?.status === 201) {
    return response;

  } else {
    
    console.log("response: ", response);
    console.log("response?.status: ", response?.status);

    toastMessage(
      `${"Something went wrong!"}`,
      "error"
    );

    // throw new Error(`response?.status: ${response?.status}`);
  }
}
const showError = (err) => {
  console.log("error: ", err);

  if (err?.response?.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
  }
  if (err?.response?.status === 400) {
    return err?.response?.data?.errors?.forEach(errorObject => {
      toastMessage(
        `${errorObject.msg || err?.response?.statusText}`,
        "error"
      );
    });
  }
  if (err?.response?.status === 404) {
    toastMessage(
      `${err?.response?.data?.message || "Not found!"}`,
      "error"
    );
  }
  toastMessage(
    `${"Something went wrong!"}`,
    "error"
  );
}


export const callAxios = async (method, route, body) => {
  const token = localStorage.getItem("token");
  if (method === "get") {
    return await axios[method](`${backendUrl}/${route}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      showError(err);
    });
  } else if (method === "delete") {
    if (body) {
      return await axios[method](`${backendUrl}/${route}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch((err) => {
        showError(err);
      });
    } else {
      // return await axios[method](`${backendUrl}/${route}`, {
      const response = await axios[method](`${backendUrl}/${route}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch((err) => {
        showError(err);
        // return err;
      });

      // to do: do this for all the above methods as well
      return checkStatus(response);
    }
  } else {
    return await axios[method](`${backendUrl}/${route}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      showError(err);
    });
  }
};

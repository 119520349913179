import {
  DateTimePickerElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  RadioButtonGroup,
} from 'react-hook-form-mui';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from "@mui/material/Modal";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { SketchPicker } from 'react-color';
import { CropperModal } from '../../Common/ImageCropper';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import dayjs from 'dayjs';

type FormProps = {
  name: string;
  description: string;

  // sizeSmall: number | undefined;
  sizeSmall?: number;
  sizeMedium?: number;
  sizeLarge?: number;

  // sizeSmall: string | null;
  // sizeMedium: string | null;
  // sizeLarge: string | null;

  sizeSmallUnit?: string[];
  sizeMediumUnit?: string[];
  sizeLargeUnit?: string[];

  // sizeSmallUnit: string | null;
  // sizeMediumUnit: string | null;
  // sizeLargeUnit: string | null;

  priceSmall?: number;
  priceMedium?: number;
  priceLarge?: number;
  singlePrice?: number;

  productCategory: string;
  deliveryPrice: number;
  deliveryTime: number;
  // deliveryDateTime: dayjs.Dayjs | null;
  images: string[];
  colors?: string[];
};
interface SelectedColor {
  index: number | null; // `null` for new colors, number for editing
  color: string;        // Color value
}
const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
  deleteButton: {
    position: 'absolute' as 'absolute',
    top: '-8px', // Adjust the position slightly to make it fit better with the image
    right: '-8px',
    backgroundColor: '#FF5C5C', // Red background for delete action
    color: '#fff', // White icon color
    padding: '4px', // Adjust padding for smaller button
    borderRadius: '50%', // Ensures a circular shape
    cursor: 'pointer',
    border: 'none',
    fontSize: '12px', // Smaller size for extra small
    width: '20px', // Adjust width of the button
    height: '20px', // Adjust height of the button
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',  // Soft shadow to give it depth
    transition: 'background-color 0.3s ease, transform 0.2s', // Smooth transitions
  },
  deleteButtonHover: {
    backgroundColor: '#FF3B3B',  // Slightly darker red on hover
    transform: 'scale(1.1)', // Slightly enlarge the button on hover for interactivity
  },
};
const styleRadio = {
  ...styles.root,
  '& .MuiFormControlLabel-label': {
    // paddingRight: "10px"
    pr: 1.2 // by default multipies by 8
  },
  '& .css-ahj2mt-MuiTypography-root': {
    fontSize: "14px"
  },
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};
const styleColorBox = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "fit-content",
  // bgcolor: 'white',
  // border: '1px solid #fff',
  // borderRadius: '12px',
  // p: 2,
  // boxShadow: 24,
  // p: 4,
};

function EditProduct({
  setOpen,
  setProducts,
  selectedProduct,
  setSelectedProduct,
  productCategories,
}) {
  const defaultValues: FormProps = {
    name: selectedProduct?.name,
    images: selectedProduct?.images,
    colors: selectedProduct?.colors,
    description: selectedProduct?.description,
    productCategory: selectedProduct?.productCategory,
    deliveryPrice: selectedProduct?.deliveryPrice,
    deliveryTime: selectedProduct?.deliveryTime,
    // deliveryDateTime: selectedProduct?.deliveryDateTime,
    sizeSmall: selectedProduct?.sizeSmall,
    sizeMedium: selectedProduct?.sizeMedium,
    sizeLarge: selectedProduct?.sizeLarge,

    sizeSmallUnit: selectedProduct?.sizeSmallUnit,
    sizeMediumUnit: selectedProduct?.sizeMediumUnit,
    sizeLargeUnit: selectedProduct?.sizeLargeUnit,

    priceSmall: selectedProduct?.priceSmall,
    priceMedium: selectedProduct?.priceMedium,
    priceLarge: selectedProduct?.priceLarge,

    singlePrice: selectedProduct?.singlePrice,
  };
  const [loading, setLoading] = useState(false);

  // const [src, setSrc] = useState(null);
  // const [preview, setPreview] = useState(null);
  // const [image, setImage] = useState('');
  // const [modalOpen, setModalOpen] = useState(false);
  const [preview, setPreview] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [colors, setColors] = useState<string[]>(selectedProduct?.colors ?? []);
  const [selectedColor, setSelectedColor] = useState<SelectedColor>({ index: null, color: '#fff' });
  const [colorModalOpen, setColorModalOpen] = useState<boolean>(false);
  const [singleProductPrice, setSingleProductPrice] = useState<number | null>(1);
  const [prices, setPrices] = useState({ priceSmall: null, priceMedium: null, priceLarge: null });

  // handle Change
  // const handleImgChange = (e) => {
  //   setSrc(URL.createObjectURL(e.target.files[0]));
  //   setModalOpen(true);
  // };

  // Handle file change (for new image upload)
  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files as FileList); // Get selected files
    const newFiles = files.map((file) => URL.createObjectURL(file)); // Create object URLs for previews

    // If multiple images are selected, update both the preview and imageFiles arrays
    setImageFiles((prevFiles) => [...prevFiles, ...files]); // Append the new files
    setPreview((prevPreview) => [...prevPreview, ...newFiles]); // Append new image previews

    // Open modal or show preview after file selection
    // setModalOpen(true);
  };

  // Delete a specific image
  const deleteImage = (index: number) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove from image files state
    setPreview((prevPreview) => prevPreview.filter((_, i) => i !== index)); // Remove from preview list
  };

  const saveColor = (index: number | null, color: string, e: any) => {

    // console.log("event: ", e);
    // console.log("index: ", index);
    // console.log("color: ", color);

    if (index !== null) {

      const colorsCopy = [...colors];
      colorsCopy[index] = color;
      setColors(colorsCopy);

    } else {
      setColors((prev) => [...prev, color]);
    }
    setColorModalOpen(false);

    // the updated colors array's element value will be shown in the next render
    // console.log("colors: ", colors);
  };

  const deleteColor = (index, e) => {
    e.stopPropagation(); // preventing the event from bubbling up and opening the color modal
    setColors((prev) => prev.filter((_, i) => i !== index));
  };
  // const addColor = () => {
  //   // add an empty string element in colors array to make a new color picker
  //   setColors((prev) => [...prev, '']);
  //   // setSelectedColor({ index: null, color: '#fff' });
  //   setColorModalOpen(true);
  // };
  // const editColor = (index: number | null, color: any) => {
  //   setSelectedColor({ index: index, color: color });
  //   setColorModalOpen(true);
  // };
  const openColorModal = (index: number | null = null, color: string = '#fff') => {
    setSelectedColor({ index: index, color: color });
    setColorModalOpen(true);
  };
  const closeColorModal = () => {
    setSelectedColor({ index: null, color: '#fff' });
    setColorModalOpen(false);
  };

  const setProductPriceForAllSizes = (e) => {
    // console.log("single price", e.target.value);
    // console.log("single price", singleProductPrice);

    setSingleProductPrice(e.target.value);

    // setPrices(prev => (
    //   {
    //     ...prev,
    //     priceSmall: e.target.value,
    //     priceMedium: e.target.value,
    //     priceLarge: e.target.value,
    //   }
    // ));
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);


    // if (!image) {
    //   return toastMessage("Please upload a profile picture", "error");
    // }

    try {

      const conditions = [
        { condition: values.sizeSmall && !values.sizeSmallUnit, message: 'Please add Size Unit for Small Size' },
        { condition: values.sizeSmall && (!values.priceSmall && !values.singlePrice), message: 'Please add Product Price for Small Size' },
        // { condition: values.priceSmall && !values.sizeSmall, message: 'Please add Product Size Small' },

        { condition: values.sizeMedium && !values.sizeMediumUnit, message: 'Please add Size Unit for Medium Size' },
        { condition: values.sizeMedium && (!values.priceMedium && !values.singlePrice), message: 'Please add Product Price for Medium Size' },
        // { condition: values.priceMedium && !values.sizeMedium, message: 'Please add Product Size Medium' },

        { condition: values.sizeLarge && !values.sizeLargeUnit, message: 'Please add Size Unit for Large Size' },
        { condition: values.sizeLarge && (!values.priceLarge && !values.singlePrice), message: 'Please add Product Price for Large Size' },
        // { condition: values.priceLarge && !values.sizeLarge, message: 'Please add Product Size Large' },

        // if the user mistakenly removes a price and does not remember what was it, so let the user submit the form and old price will still be present in Database.
        // or the user can close the edit modal and reopen it to get the old price and use this error message.

        // { condition: !values.priceSmall && !values.priceMedium && !values.priceLarge && !values.singlePrice, message: 'Please add Product Price' },

        { condition: values.singlePrice && (!values.sizeSmall || !values.sizeMedium || !values.sizeLarge), message: 'Please add All Sizes or Set the Prices for individual Sizes and not for All Sizes' },

        // { condition: false, message: '' },
      ];

      for (let { condition, message } of conditions) {
        if (condition) {
          return toastMessage(message, 'error');
        }
      }

      setLoading(true);

      // let result;

      // if (image) {
      //   const formData = new FormData();
      //   formData.append('file', image);
      //   result = await callAxios('post', 'app/user/uploadImage', formData);
      //   console.log(result);
      // }

      let imageUploadPromises = [];

      // Upload new images
      if (imageFiles.length) {

        imageUploadPromises = imageFiles.map(async (file: File) => {
          const formData = new FormData();
          formData.append('file', file);
          const { data: result } = await callAxios('post', 'app/user/uploadImage', formData);
          return result.url;
        });
      }


      const imageUrls = await Promise.all(imageUploadPromises);

      // if (values.price === selectedProduct?.price) {
      //   delete values.price;
      // }

      let payload = {
        ...values,
        // images: image ? [result?.data?.url] : selectedProduct?.images,
        images: imageUrls.length ? [...selectedProduct?.images, ...imageUrls] : selectedProduct?.images,
      };

      // setting single price for all product sizes
      if (payload.singlePrice) {
        // console.log("payload.singlePrice: ", payload.singlePrice);

        payload = {
          ...payload,
          priceSmall: payload.singlePrice,
          priceMedium: payload.singlePrice,
          priceLarge: payload.singlePrice,
        }
        // console.log("payload: ", payload);
      }
      // return;

      if (colors.length) {
        // console.log("colors: ", colors);

        payload = {
          ...payload,
          colors: colors,
        }
        // console.log("payload: ", payload);
      }

      if (!payload.sizeSmall) {
        // console.log("payload.sizeSmall: ", payload.sizeSmall);

        delete payload.sizeSmall;
        delete payload.sizeSmallUnit;
        delete payload.priceSmall;
      }
      if (!payload.sizeMedium) {
        delete payload.sizeMedium;
        delete payload.sizeMediumUnit;
        delete payload.priceMedium;
      }
      if (!payload.sizeLarge) {
        delete payload.sizeLarge;
        delete payload.sizeLargeUnit;
        delete payload.priceLarge;
      }

      let filteredPayload = Object.fromEntries(
        Object.entries(payload).filter(([_, value]) => value !== "" && value !== null)
      );
      // console.log("filteredPayload: ", filteredPayload);

      // doing until the new fields are not used in app
      const priceData = {
        priceSmall: filteredPayload.priceSmall,
        priceMedium: filteredPayload.priceMedium,
        priceLarge: filteredPayload.priceLarge,
        singlePrice: filteredPayload.singlePrice,
      };

      let price = Object.values(priceData).find(value => value != null && value != 0) || 0;

      console.log("Price:", price);

      filteredPayload = { ...filteredPayload, price: price };

      const { data } = await callAxios(
        'put',
        `dashboard/products/updateProduct?id=${selectedProduct?._id}`,
        filteredPayload
      );
      console.log(data);
      setLoading(false);
      setOpen(false);
      setProducts((prev) =>
        prev.map((el) => {
          if (el?._id == data.data?._id) return data.data;
          else return el;
        })
      );
      toastMessage('Product Product Created Successfully', 'success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong.',
        'error'
      );
    }
  };
  return (
    // <Box sx={style}>
    <Box sx={style} className="overflow-y-auto h-full" >
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center">
          Edit Product
        </h5>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full flex flex-col items-center gap-7">
            <div className="w-full flex flex-col gap-1 ">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Product Name
                </p>
                <TextFieldElement
                  name="name"
                  fullWidth
                  size="small"
                  placeholder="Enter Product Name"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-1 ">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Product Desscription
                </p>
                <TextFieldElement
                  name="description"
                  fullWidth
                  size="small"
                  placeholder="Enter Product description"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            {/* Product Sizes */}
            <p className="publicSans font-medium leading-5 text-[#777980]">Product Sizes</p>
            <div className="w-full grid grid-cols-3 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Size Small (cm/inch)</p>
                <TextFieldElement
                  name="sizeSmall"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Size"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
                <div>
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Unit for Small</p>
                  {/* <Box sx={styleRadio}> */}
                  <RadioButtonGroup
                    // label="Basic"
                    name="sizeSmallUnit"
                    options={[
                      {
                        id: 'cm',
                        label: 'cm'
                      },
                      {
                        id: 'inch',
                        label: 'inch'
                      }
                    ]}
                    row
                  />
                  {/* </Box> */}
                </div>

                {/* <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label"
                    style={{ fontSize: '14px' }}
                  >
                    Unit for Small
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="sizeSmallUnit"
                  >
                    <FormControlLabel value="cm" control={<Radio size="small" />} label="cm" sx={styleRadio} />
                    <FormControlLabel value="inch" control={<Radio size="small" />} label="inch" sx={styleRadio} />
                  </RadioGroup>
                </FormControl> */}

              </div>

              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Size Medium (cm/inch)</p>
                <TextFieldElement
                  name="sizeMedium"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Size"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
                <div>
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Unit for Medium</p>
                  {/* <Box sx={styleRadio}> */}
                  <RadioButtonGroup
                    // label="Basic"
                    name="sizeMediumUnit"
                    options={[
                      {
                        id: 'cm',
                        label: 'cm'
                      },
                      {
                        id: 'inch',
                        label: 'inch'
                      }
                    ]}
                    row
                  />
                  {/* </Box> */}
                </div>
                {/* <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label"
                    style={{ fontSize: '14px' }}
                  >
                    Unit for Medium
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="sizeMediumUnit"
                  >
                    <FormControlLabel value="cm" control={<Radio size="small" />} label="cm" sx={styleRadio} />
                    <FormControlLabel value="inch" control={<Radio size="small" />} label="inch" sx={styleRadio} />
                  </RadioGroup>
                </FormControl> */}
              </div>

              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Size Large (cm/inch)</p>
                <TextFieldElement
                  name="sizeLarge"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Size"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
                <div>
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Unit for Large</p>
                  {/* <Box sx={styleRadio}> */}
                  <RadioButtonGroup
                    // label="Basic"
                    name="sizeLargeUnit"
                    options={[
                      {
                        id: 'cm',
                        label: 'cm'
                      },
                      {
                        id: 'inch',
                        label: 'inch'
                      }
                    ]}
                    row
                  />
                  {/* </Box> */}
                </div>
                {/* <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label"
                    style={{ fontSize: '14px' }}
                  >
                    Unit for Large
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="sizeLargeUnit"
                  >
                    <FormControlLabel value="cm" control={<Radio size="small" />} label="cm" sx={styleRadio} />
                    <FormControlLabel value="inch" control={<Radio size="small" />} label="inch" sx={styleRadio} />
                  </RadioGroup>
                </FormControl> */}
                {/* <FormControlLabel value="cm" control={<Radio size="small"/>} label="cm" sx={{...styles.root, ...{'& .MuiFormControlLabel-label': { padding: '10px' } }}}/> */}
                {/* <FormControlLabel value="cm" control={<Radio size="small"/>} label="cm" className='text-sm text-red-600'/> */}
              </div>
            </div>

            {/* Product Prices */}
            <p className="publicSans font-medium leading-5 text-[#777980]">Product Prices according to Sizes</p>
            <div className="w-full grid grid-cols-3 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Price Small</p>
                <TextFieldElement
                  name="priceSmall"
                  // value={singleProductPrice}
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Price Medium</p>
                <TextFieldElement
                  name="priceMedium"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Price Large</p>
                <TextFieldElement
                  name="priceLarge"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                // required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Price for All Sizes</p>
                <TextFieldElement
                  name="singlePrice"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                  onChange={setProductPriceForAllSizes}
                // required
                />
              </div>
            </div>
            {/* <div className="w-full flex flex-col gap-1 ">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Product Price
                </p>
                <TextFieldElement
                  name="price"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                  required
                />
              </div>
            </div> */}
            <div className="w-full grid grid-cols-3 gap-4">

              <div className="w-full flex flex-col gap-1 ">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Product category
                  </p>
                  <SelectElement
                    name="productCategory"
                    fullWidth
                    size="small"
                    placeholder="Center Name"
                    sx={styles.root}
                    options={productCategories}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Delivery Price</p>
                <TextFieldElement
                  name="deliveryPrice"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Delivery Price"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Delivery Time in Days</p>
                <TextFieldElement
                  name="deliveryTime"
                  fullWidth
                  size="small"
                  type="number"
                  placeholder="Enter Delivery Time"
                  sx={styles.root}
                  inputProps={{ min: 1 }}
                  required
                />
              </div>
            </div>

            {/* <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Delivery Date Time</p>
              <DateTimePickerElement
                name="deliveryDateTime"
                sx={styles.root}
                inputProps={{
                  size: 'small',
                  fullWidth: true,
                }}
                disablePast={true}
                required
              />
            </div> */}
            {/* <div className="w-full flex flex-col gap-1 ">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                Product Picture / Product Logo
              </h6>
              <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center">
                  <div className="rounded-lg bg-[#8CC8E4] p-3 ">
                    <img
                      src={
                        preview
                          ? preview
                          : selectedProduct?.images?.length > 0
                            ? selectedProduct?.images[0]
                            : '/assets/icons/picture.png'
                      }
                      alt="icon"
                      className="w-4 h-4"
                    />
                  </div>
                  <p className="publicSans text-sm font-normal leading-5 text-gray-400">
                    Drag and drop image here, or click add image
                  </p>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Image
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      onChange={handleImgChange}
                    />
                  </Button>
                </div>
              </div>
            </div> */}

            {/* Product Colors */}
            <div className="w-full flex flex-col items-start gap-y-6">
              <div className="w-full flex flex-col gap-y-7">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Product Colors</p>
                {/* Colors Section */}
                <div className="w-full flex flex-wrap gap-7">
                  {colors.map((color, index) => (

                    // Individual Color
                    <div key={index}>
                      {/* using Input Field to add Colors Names */}
                      {/* <input
                      type="text"
                      value={color}
                      onChange={(e) => handleColorChange(index, e.target.value)}
                      placeholder={`Color ${index + 1}`}
                    /> */}
                      {/* <SketchPicker
                      color={color}
                      // more cpu usage, works smooth while click-and-dragging
                      // onChange={(color, e) => handleColorChange(index, color, e)}
                      // less cpu usage, works jittery while click-and-dragging
                      onChangeComplete={(color, e) => handleColorChange(index, color, e)}
                    /> */}

                      <div
                        style={{ backgroundColor: color }}
                        className='w-fit relative rounded-full border p-6'
                        onClick={() => openColorModal(index, color)}
                      >
                        {/* Close button */}
                        <div className="w-fit absolute -right-3 -top-3 rounded-full bg-[#F2F2F2] p-1">
                          <img
                            src="/assets/icons/cross.svg"
                            alt="close"
                            className="w-3 cursor-pointer"
                            onClick={(e) => { deleteColor(index, e) }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button type='button' onClick={() => openColorModal()}
                className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl mx-auto px-6 py-2"
              >
                Add Color
              </button>
            </div>
            <div className="w-full flex flex-col gap-1">
              <h6 className="text-tertiary publicSans text-base font-semibold">
                Product Pictures
              </h6>
              <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                <div className="flex flex-col gap-4 items-center w-full">
                  <div className="rounded-lg p-3 w-full">
                    {preview.length > 0 ? (
                      <div className="grid grid-cols-4 gap-4">
                        {preview.map((img, index) => (
                          <div key={index} className="relative text-center bg-[#E8F6FF] p-3 rounded-lg shadow-md hover:shadow-lg transition-all">
                            {/* The background color here is distinct for each image */}
                            <img
                              src={img}
                              alt={`preview-${index}`}
                              className="w-16 h-16 object-cover mx-auto" // Centering the image
                            />
                            <p className="text-xs mt-2">{imageFiles[index]?.name}</p>
                            <button
                              type='button'
                              onClick={() => deleteImage(index)}
                              style={styles.deleteButton} // Apply the styles here
                              className="hover:bg-red-600 hover:scale-110" // Add hover effect classes from tailwind or use inline styles as well
                            >
                              <CloseIcon style={{ color: '#fff', fontSize: '12px' }} /> {/* MUI Close icon */}
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <img src="/assets/icons/picture.png" alt="icon" className="w-4 h-4 mx-auto" />
                    )}
                  </div>
                  <Button
                    className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5"
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Add Images
                    <VisuallyHiddenInput
                      type="file"
                      required
                      accept="image/*"
                      onChange={handleImgChange}
                      multiple
                    />
                  </Button>

                </div>
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                Edit Product
              </button>
            </div>
          </div>
        </FormContainer>
      </div>
      {/* Product Colors Modal */}
      <Modal
        open={colorModalOpen}
        onClose={closeColorModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={styleColorBox}>
          <div className='w-fit h-fit flex flex-col gap-y-4 justify-center items-center relative'>
            <div className="absolute rounded-full bg-[#F2F2F2] p-1 w-fit -right-4 -top-4">
              <img
                src="/assets/icons/cross.svg"
                alt="close"
                className="cursor-pointer"
                onClick={closeColorModal}
              />
            </div>
            <SketchPicker
              color={selectedColor.color}
              // more cpu usage, works smooth while click-and-dragging
              // onChange={(color, e) => handleColorChange(index, color, e)}
              // less cpu usage, works jittery while click-and-dragging
              onChangeComplete={(color, e) => setSelectedColor(prev => ({ ...prev, color: color.hex }))}
            />

            <button type='button' onClick={(e) => saveColor(selectedColor.index, selectedColor.color, e)}
              className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"

            >
              Save Color</button>

          </div>
        </Box>
      </Modal>
      {/* <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      /> */}
    </Box>
    // </Box>
  );
}

export default EditProduct;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import Loading from "react-fullscreen-loading";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from "../../../utils/axios";
// Table Imports
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import dayjs from "dayjs";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#fff", // Customize cell background color
    fontWeight: 500, // Customize cell text styling
    fontFamily: "Public Sans",
    fontSize: "14px",
    lineHeight: "20px",
    borderBottom: "none",
    color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#fff", // Customize cell background color
    fontFamily: "Public Sans",
    fontSize: "14px",
    fontWeight: 500, // Customize cell text styling
    lineHeight: "20px",
    borderBottom: "none",
    color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "#fff", // Customize table head row background color
    border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
    marginBottom: "10px",
}));

const PaymentList = () => {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [payments, setPayments] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [dataRange, setDataRange] = useState("");
    const [status, setStatus] = useState("All");

    const getAllPayments = async () => {
        try {
            setLoading(true);
            const { data } = await callAxios(
                "get",
                `dashboard/payments?page=${page}&limit=10&search=${searchText}&status=${status}`
            );
            setPayments(data?.data);
            setCount(data?.totalData);
            setTotalPages(data?.totalPage);
            setDataRange(
                "Showing" +
                (data?.currentPage * 10 - 9) +
                "-" +
                data?.currentPage * 10 +
                " of " +
                data?.totalData
            );
            setLoading(false);
        } catch (error) {

            setLoading(false);
            toastMessage(
                error?.response?.data?.message || "Something went wrong",
                "error"
            );
        }
    };
    useEffect(() => {
        getAllPayments();
    }, [page, status, searchText]);

    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

            <div className="w-full flex flex-col gap-6 ">
                <div className="w-full flex flex-col gap-2">
                    <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
                        Payments
                    </h6>
                    <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
                        Payments
                    </p>
                </div>
                <div className="w-full flex flex-row justify-between gap-5 bg-white rounded-lg shadow p-4 px-8 ">
                    <div className="w-2/3 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
                        <img src="/assets/icons/search.svg" alt="" className="" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>

                    <div className='flex justify-around'>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
                        >
                            <option value="" className="text-gray-400" disabled>
                                Status
                            </option>
                            <option value="All" className="text-gray-700">
                                All
                            </option>
                            <option value="true" className="text-gray-700">
                                Paid
                            </option>
                            <option value="false" className="text-gray-700">
                                Pending
                            </option>
                        </select>
                    </div>

                </div>
                <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
                    <div className="w-full">
                        <Table
                            sx={{
                                borderCollapse: "separate !important",
                                width: "100%",
                            }}
                        >
                            <TableHead>
                                <CustomTableHeadRow>
                                    <CustomTableHeadCell>Vet Name</CustomTableHeadCell>
                                    <CustomTableHeadCell>Date</CustomTableHeadCell>
                                    <CustomTableHeadCell>Total Amount</CustomTableHeadCell>
                                    <CustomTableHeadCell>Commission Amount</CustomTableHeadCell>
                                    <CustomTableHeadCell>Stripe Deduction</CustomTableHeadCell>
                                    <CustomTableHeadCell>User Name</CustomTableHeadCell>
                                    <CustomTableHeadCell>Module Type</CustomTableHeadCell>
                                    <CustomTableHeadCell>Gross Amount</CustomTableHeadCell>
                                    <CustomTableHeadCell>Status</CustomTableHeadCell>
                                    <CustomTableHeadCell>Payment Indent Id</CustomTableHeadCell>
                                </CustomTableHeadRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((payments: any) => {
                                    return (
                                        <CustomTableBodyRow key={payments._id}>
                                            <CustomTableBodyCell>{payments?.vetId?.centerName}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{dayjs(payments?.date).isValid() ? dayjs(payments?.date).format('DD-MM-YYYY') : ''}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.total}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.commissionAmount}</CustomTableBodyCell>
                                            <CustomTableBodyCell >{payments?.stripeAmount}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.userId?.fullname}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.moduleName}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.grossAmount}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.status ? "Paid" : "Pending"}</CustomTableBodyCell>
                                            <CustomTableBodyCell>{payments?.paymentIntentId}</CustomTableBodyCell>
                                        </CustomTableBodyRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="w-full flex justify-between items-center p-4">
                        <p className="publicSans text-sm font-medium text-[#667085] leading-5">
                            {dataRange}
                        </p>
                        <Pagination
                            page={page}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="secondary"
                            onChange={(e, value) => setPage(value)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentList;

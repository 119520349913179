import { Box, IconButton, Modal, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Loading from 'react-fullscreen-loading';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import moment from 'moment';
// Table Imports
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useNavigate } from 'react-router';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const PetVaccine = ({ id }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');
  const [history, setHistory] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const getAllHistory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `app/pet/getPetVaccine?page=${page}&limit=10&id=${id}`
      );
      console.log(data);

      setHistory(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getAllHistory();
  }, [page]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full">
          <Table
            sx={{
              borderCollapse: 'separate !important',
              width: '100%',
            }}
          >
            <TableHead>
              <CustomTableHeadRow>
                <CustomTableHeadCell>Manufacturer</CustomTableHeadCell>
                <CustomTableHeadCell>Name of Vaccine</CustomTableHeadCell>
                <CustomTableHeadCell>Vaccination Date</CustomTableHeadCell>
                <CustomTableHeadCell>Valid Until</CustomTableHeadCell>
                <CustomTableHeadCell>
                  Authorized Veterinarian
                </CustomTableHeadCell>
                <CustomTableHeadCell>Document</CustomTableHeadCell>
                <CustomTableHeadCell>Image</CustomTableHeadCell>
              </CustomTableHeadRow>
            </TableHead>
            <TableBody>
              {history.map((vaccine: any) => {
                return (
                  <CustomTableBodyRow key={vaccine?._id}>
                    <CustomTableBodyCell>
                      {vaccine?.manufacturer}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>{vaccine?.name}</CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {moment(vaccine?.vaccinationDate).format('DD-MM-YYYY')}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {moment(vaccine?.validUntil).format('DD-MM-YYYY')}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {vaccine?.authVeterinarian}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {vaccine?.documentUrl ? (
                        <a
                          target="_blank"
                          href={vaccine?.documentUrl}
                          style={{
                            textDecoration: 'underline',
                            color: '#49b3f6',
                          }}
                          rel="noreferrer"
                        >
                          Download
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      {vaccine?.imageProofUrl ? (
                        <img
                          onClick={() => {
                            setPreviewImage(vaccine?.imageProofUrl);
                            setPreviewOpen(true);
                          }}
                          src={vaccine?.imageProofUrl}
                          style={{ width: '80px', cursor: 'pointer' }}
                          alt="Proof"
                        />
                      ) : (
                        'N/A'
                      )}
                    </CustomTableBodyCell>
                  </CustomTableBodyRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="w-full flex justify-end items-center p-4">
          <Pagination
            page={page}
            count={totalPages}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={(e, value) => setPage(value)}
          />
        </div>
      </div>
      <Modal
        open={previewOpen}
        style={{
          width: '360px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClose={() => setPreviewOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ bgcolor: 'transparent', width: '360px', padding: 0 }}>
          <Stack justifyContent="center" alignItems="center">
            <img
              src={previewImage}
              alt=""
              width={'360px'}
              style={{ borderRadius: 12 }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',

                backgroundColor: '#fff',
              }}
              onClick={() => setPreviewOpen(false)}
            >
              <img
                src="/assets/icons/cross.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => setPreviewOpen(false)}
              />
            </IconButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default PetVaccine;

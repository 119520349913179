import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import DeleteService from "./DeleteService";
import AddService from "./AddService";
import EditService from "./EditService";
import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from "../../../utils/axios";
// Table Imports
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Switch from "@mui/material/Switch";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const ServicesTable = ({ checked, setChecked }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [services, setServices] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useAppSelector((state) => state?.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [existingData, setExistingData] = useState(null as any);
  const [searchText, setSearchText] = useState("");
  const [dataRange, setDataRange] = useState("");
  const [status, setStatus] = useState("All");

  const getAllServices = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/services/getAllServices?page=${page}&limit=10&status=${status}&search=${searchText}`
        // `dashboard/services/getAllServices?page=${page}&limit=10&vet=${user?._id}&status=${status}&search=${searchText}`
      );
      console.log(data);

      setServices(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        "Showing" +
        (data?.currentPage * 10 - 9) +
        "-" +
        data?.currentPage * 10 +
        " of " +
        data?.totalData
      );

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const handleEnableDisable = async (event, id: any) => {
    // console.log(event.target.checked, id);
    console.log("event.target.checked: ", event.target.checked);

    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/services/updateService?id=${id}`,
        {
          active: event.target.checked,
        }
      );

      // console.log("data: ", data);

      toastMessage(
        `Service ${event.target.checked ? "Disabled" : "Enabled"
        } successfully`,
        "success"
      );
      setServices((prev) =>
        prev.map((service) => {
          if (service._id == id) return data?.data;
          else return service;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    if (user) {
      getAllServices();
    }
  }, [page, status, searchText, checked, user]);

  return (
    <>
      <div className="w-full flex flex-col gap-6 ">

        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Services
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Services
          </p>
        </div>
        <div className="w-full flex flex-row justify-between gap-5 bg-white rounded-lg shadow p-4 px-8 ">
          <div className="w-2/3 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className='flex justify-around'>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
            >
              <option value="" className="text-gray-400" disabled>
                Status
              </option>
              <option value="All" className="text-gray-700">
                All
              </option>
              <option value="true" className="text-gray-700">
                Enable
              </option>
              <option value="false" className="text-gray-700">
                Disable
              </option>
            </select>
          </div>
          
          {
            user?.userType === "VET" &&
            <div className="w-max flex justify-center items-center">
              <button
                className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"
                onClick={() => setAddOpen(true)}
              >
                Add Service
              </button>
            </div>
          }
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Service Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Vet Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Pet Types</CustomTableHeadCell>
                  <CustomTableHeadCell>Price</CustomTableHeadCell>
                  <CustomTableHeadCell>Service Type</CustomTableHeadCell>
                  <CustomTableHeadCell>Time</CustomTableHeadCell>
                  <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell>
                  <CustomTableHeadCell>Modify</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {services.map((service: any) => {
                  return (
                    <CustomTableBodyRow key={service?._id}>
                      <CustomTableBodyCell>
                        <div className="flex items-center gap-2">
                          <img
                            src={
                              service?.images?.length > 0
                                ? service?.images[0]
                                : "/assets/images/user-logo.png"
                            }
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                          <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                            {service?.name}
                          </p>
                        </div>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {service?.vet?.fullname}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {service?.petCategory?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{service?.price}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {service?.serviceTypeCheck}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {service?.time > 60
                          ? `${Math.floor(service?.time / 60)} hour ${service?.time % 60
                          } min`
                          : `${service?.time} min`}
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className="flex justify-center">
                          <Switch
                            checked={service?.active}
                            onChange={(e) => handleEnableDisable(e, service?._id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                          />
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className="flex gap-2">
                          <button
                            onClick={() => {
                              setExistingData(service);
                              setEditOpen(true);
                            }}
                            className="text-white publicSans text-sm font-medium leading-5 bg-secondary py-1 px-2 rounded "
                          >
                            Edit
                          </button>
                          {/* <button
                            onClick={() => {
                              setExistingData(service);
                              setDeleteOpen(true);
                            }}
                            className="text-white publicSans text-sm font-medium leading-5 bg-[#EF8214] py-1 px-2 rounded "
                          >
                            Delete
                          </button> */}
                        </div>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
        <Modal
          open={addOpen}
          onClose={() => setAddOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <AddService
            setOpen={setAddOpen}
            setServices={setServices}
          />
        </Modal>
        <Modal
          open={editOpen}
          onClose={() => setEditOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <EditService
            setOpen={setEditOpen}
            existingData={existingData}
            setServices={setServices}
            checked={checked}
            setChecked={setChecked}
          />
        </Modal>
        <Modal
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <DeleteService
            setOpen={setDeleteOpen}
            existingData={existingData}
            setServices={setServices}
            checked={checked}
            setChecked={setChecked}
          />
        </Modal>
      </div>

    </>
  );
};

export default ServicesTable;
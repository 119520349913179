import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const DeleteService = ({
  setOpen,
  existingData,
  setServices,
  checked,
  setChecked,
}: any) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const data = await callAxios(
        "delete",
        `dashboard/services/deleteService/${existingData?._id}`,
      );

      console.log("data: ", data);

      setLoading(false);

      if (data) {

        setServices((prev) =>
          prev.filter((service) => service._id !== existingData?._id)
        );

        setOpen(false);

        toastMessage(
          data?.data?.message || `Service Deleted Successfully!`,
          "success"
        );
      }

      // setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
          Delete Service
        </h5>
        <p className="publicSane text-center text-gray-500 ">
          Are you Sure, you want to Delete this service?
        </p>
        <div className="w-full flex justify-end gap-4">
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="text-white publicSans text-sm font-medium leading-5 bg-secondary py-2 px-4 rounded "
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleDelete();
            }}
            className="text-white publicSans text-sm font-medium leading-5 bg-red-500 py-2 px-4 rounded "
          >
            Delete
          </button>
        </div>
      </div>
    </Box>
  );
};

export default DeleteService;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import styles from "./SubscriptionPreferences.module.css";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";

function SubscriptionPreferences() {

    const [loading, setLoading] = useState(false);
    const [subscribed, setSubscribed] = useState(true);

    const navigate = useNavigate();

    
    // to do: check how the user will be identified in backend. like where is the email, token or _id of user.
    const handleUnsubscribe = async (e: any) => {

        try {
            setLoading(true);
            const { data } = await callAxios(
                "put",
                `dashboard/gdpr/unsubscribe`,
                {}
            );

            toastMessage(
                data?.message,
                "success"
            );
            
            setSubscribed(false);

            navigate("/privacypolicy");

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
            toastMessage(
                error?.response?.data?.message || "Something went wrong",
                "error"
            );
        }
    };

    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

            <div className="flex justify-center items-center flex-col gap-4 h-screen">
                <h1 className="font-bold text-2xl">Unsubscribe</h1>
                {subscribed ? (
                    <div className="text-center">
                        <p>You're currently subscribed to our service.</p>
                        <button
                            onClick={handleUnsubscribe}
                            className="bg-blue-700 text-white rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-800 mt-7 px-6 py-2"
                        >
                            Unsubscribe
                        </button>
                    </div>
                ) : (
                    <p>You have successfully unsubscribed from our service.</p>
                )}
            </div>
        </>
    )
}
export default SubscriptionPreferences;
import { adminRoutes, adoptionRoutes, taxiProviderRoutes, vetRoutes } from "../../constants/routes";
import { useLocation, useNavigate } from "react-router";

import Header from "./Header";
import React from "react";
import { useAppSelector } from "../../store/hooks";

const Sidebar = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state?.user);

  if (location.pathname === "/login" || location.pathname === "/forgot-password" || location.pathname === "/verify-otp" || location.pathname === "/reset-password" || location.pathname === "/privacypolicy" || location.pathname === "/unsubscribe") {
    return children;
  }

  return (
    <>
      <div className="w-full grid grid-cols-12 h-full min-h-screen ">
        <div className="col-span-2 bg-white sidebar-shadow py-6 flex flex-col gap-8">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="w-16 h-12 mx-6"
          />
          <div className="flex flex-col w-full gap-4">
            {user?.userType === "ADMIN" &&
              adminRoutes.map((route) => {
                return (
                  <div
                    className={`flex gap-2 w-full items-center cursor-pointer py-2 px-6  border-l-4 ${
                      location.pathname === route.path
                        ? "text-[#1F9CB9] bg-[#F0F7FC]  border-[#1F9CB9] "
                        : "border-white"
                    } `}
                    onClick={() => navigate(route.path)}
                    key={route.name}
                  >
                    <img
                      src={route.icon}
                      alt={route.name}
                      className="w-4 h-4"
                    />
                    <p className="publicSans text-sm font-semibold leading-5">
                      {route.name}
                    </p>
                  </div>
                );
              })}
            {user?.userType === "ADOPTION" &&
              adoptionRoutes.map((route) => {
                return (
                  <div
                    className={`flex gap-2 w-full items-center cursor-pointer py-2 px-6  border-l-4 ${
                      location.pathname === route.path
                        ? "text-[#1F9CB9] bg-[#F0F7FC]  border-[#1F9CB9] "
                        : "border-white"
                    } `}
                    onClick={() => navigate(route.path)}
                    key={route.name}
                  >
                    <img
                      src={route.icon}
                      alt={route.name}
                      className="w-4 h-4"
                    />
                    <p className="publicSans text-sm font-semibold leading-5">
                      {route.name}
                    </p>
                  </div>
                );
              })}
            {user?.userType === "VET" &&
              vetRoutes.map((route) => {
                return (
                  <div
                    className={`flex gap-2 w-full items-center cursor-pointer py-2 px-6  border-l-4 ${
                      location.pathname === route.path
                        ? "text-[#1F9CB9] bg-[#F0F7FC]  border-[#1F9CB9] "
                        : "border-white"
                    } ${route?.pending ? "bg-red-600 text-white" : ""}`}
                    onClick={() => navigate(route.path)}
                    key={route.name}
                  >
                    <img
                      src={route.icon}
                      alt={route.name}
                      className="w-4 h-4"
                    />
                    <p className="publicSans text-sm font-semibold leading-5">
                      {route.name}
                    </p>
                  </div>
                );
              })}
            {user?.userType === "TAXIPROVIDER" &&
              taxiProviderRoutes.map((route) => {
                return (
                  <div
                    className={`flex gap-2 w-full items-center cursor-pointer py-2 px-6  border-l-4 ${
                      location.pathname === route.path
                        ? "text-[#1F9CB9] bg-[#F0F7FC]  border-[#1F9CB9] "
                        : "border-white"
                    } `}
                    onClick={() => navigate(route.path)}
                    key={route.name}
                  >
                    <img
                      src={route.icon}
                      alt={route.name}
                      className="w-4 h-4"
                    />
                    <p className="publicSans text-sm font-semibold leading-5">
                      {route.name}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-span-10 bg-[#F9F9FC] w-full p-4 flex flex-col">
          <Header />
          {children}
        </div>
      </div>
    </>
  );
};

export default Sidebar;

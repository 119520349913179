export const adminRoutes = [
  {
    name: "User Management",
    path: "/admin/user-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Notifications",
    path: "/admin/notifications",
    icon: "/assets/icons/notifications.svg",
  },
  {
    name: "Emergency Vets",
    path: "/admin/emergency-vets",
    icon: "/assets/icons/notifications.svg",
  },
  {
    name: "Account Creation",
    path: "/admin/account-creation",
    icon: "/assets/icons/account-creation.svg",
  },
  {
    name: "Pet Passport Database",
    path: "/admin/pet-passport-database",
    icon: "/assets/icons/pet-passport.svg",
  },
  {
    name: "Appointments",
    path: "/admin/appointments",
    icon: "/assets/icons/appointment.svg",
  },
  {
    name: "Financial Management",
    path: "/admin/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Services",
    path: "/admin/services",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Pet Category",
    path: "/admin/pet-category",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Product Category",
    path: "/admin/product-category",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Products",
    path: "/admin/products",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Advertisement",
    path: "/admin/advertisements",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Orders",
    path: "/admin/orders",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Coupons",
    path: "/admin/coupons",
    icon: "/assets/icons/pet-passport.svg",
  },
  {
    name: "Payments",
    path: "/admin/payments",
    icon: "/assets/icons/pet-passport.svg",
  },
];
export const adoptionRoutes = [
  {
    name: "Available Pets",
    path: "/adoption/available-pets",
    icon: "/assets/icons/available-pets.svg",
  },
  {
    name: "Adoption Center Profile",
    path: "/adoption/adoption-center-profile",
    icon: "/assets/icons/adoption-profile.svg",
  },
  {
    name: "Add a New pet",
    path: "/adoption/add-pet",
    icon: "/assets/icons/add-pet.svg",
  },
  {
    name: "Adoption History",
    path: "/adoption/adoption-history",
    icon: "/assets/icons/adoption-history.svg",
  },
];
export const vetRoutes = [
  {
    name: "Calendar",
    path: "/vet/calendar",
    icon: "/assets/icons/calendar.svg",
  },
  {
    name: "Profile",
    path: "/vet/profile",
    icon: "/assets/icons/vet-profile.svg",
  },
  {
    name: "Appointments",
    path: "/vet/appointments",
    icon: "/assets/icons/appointment.svg",
  },
  {
    name: "Driver Management",
    path: "/driver-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Mobile Grooming Van Management",
    path: "/mobile-grooming-van-man",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Mobile Grooming Management",
    path: "/mobile-grooming-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Taxi Management",
    path: "/taxi-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Financial Management",
    path: "/vet/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Services",
    path: "/vet/services",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Products",
    path: "/vet/products",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Advertisement",
    path: "/vet/advertisements",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Orders",
    path: "/vet/orders",
    icon: "/assets/icons/financial-management.svg",
  },
  // {
  //   name: "Payments",
  //   path: "/vet/payments",
  //   icon: "/assets/icons/financial-management.svg",
  //   pending: false,
  // },
];
export const taxiProviderRoutes = [
  {
    name: "Profile",
    path: "/taxiProvider/profile",
    icon: "/assets/icons/vet-profile.svg",
  },
  {
    name: "Driver Management",
    path: "/driver-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Taxi Management",
    path: "/taxi-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Financial Management",
    path: "/vet/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
];
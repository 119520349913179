import styles from "./PrivacyPolicy.module.css";
import { Link } from "react-router-dom";

function PrivacyPolicy() {

    // Note: disabled "unsubscribe" link because there is not any identity of user to fetch and update on backend
    // like email, token or _id. and when this is resolved then we will navigate to "SubscriptionPreferences" component.
    
    return (
        <div className="p-6">
            <div>
                <div>
                    <div className={`${styles["main-heading"]}`}>
                        <h2>DISCLAIMER</h2>
                         
                        Updated at November 29th, 2023
                    </div>
                    <p>Pet Republic hereby grants you access to https://www.petrepublic.ae/ (&#8220;the Website&#8221;) and
                        invites you to purchase the services offered here. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Definitions and key terms </h3>To help explain things as clearly as possible in this Disclaimer,
                        every time any of these terms are referenced, are strictly defined as: </p>
                    <p>&#9679; Cookie: small amount of data generated by a website and saved by your web browser. It is used
                        to identify your browser, provide analytics, remember information about you such as your
                        language preference or login information. </p>
                    <p>&#9679; Company: when this policy mentions &#8220;Company,&#8221; &#8220;we,&#8221; &#8220;us,&#8221; or
                        &#8220;our,&#8221; it refers to Pet Republic Portal LLC, Hamdan Innovation Incubator (Hi2) 221 Al
                        Maktoum Rd - Port Saeed - Dubai that is responsible for your information under this
                        Disclaimer. </p>
                    <p>&#9679; Service: refers to the service provided by Pet Republic as described in the relative terms (if
                        available) and on this platform. </p>
                    <p>&#9679; Website: Pet Republic&#8217;s site, which can be accessed via this URL:
                        https://www.petrepublic.ae/ &#9679; You: a person or entity that is registered with Pet Republic to
                        use the Services. </p>
                    <p>This Disclaimer was created with Termify. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Limited liability </h3>Pet Republic endeavours to update and/or supplement the content of the
                        website on a regular basis. Despite our care and attention, content may be incomplete and/or
                        incorrect. </p>
                    <p>The materials offered on the website are offered without any form of guarantee or claim to their
                        correctness. These materials can be changed at any time without prior notice from Pet
                        Republic. </p>
                    <p>Particularly, all prices on the website are stated subject to typing and programming errors. No liability
                        is assumed for the implications of such errors. No agreement is concluded on the basis of such
                        errors. </p>
                    <p>Pet Republic shall not bear any liability for hyperlinks to websites or services of third parties
                        included on the website. From our website, you can visit other websites by following hyperlinks to
                        such external sites. While we strive to provide only quality links to useful and ethical websites,
                        we have no control over the content and nature of these sites. These links to other websites do not
                        imply a recommendation for all the content found on these sites. Site owners and content may change
                        without notice and may occur before we have the opportunity to remove a link which may have gone
                        &#8216;bad&#8217;. </p>
                    <p>Please be also aware that when you leave our website, other sites may have different privacy policies
                        and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites
                        as well as their &quot;Terms of Service&quot; before engaging in any business or uploading any
                        information. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Links to Other Websites Disclaimer</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>This Disclaimer applies only to the Services. The Services may contain links to other websites not
                        operated or controlled by Pet Republic. We are not responsible for the content, accuracy or
                        opinions expressed in such websites, and such websites are not investigated, monitored or checked
                        for accuracy or completeness by us. Please remember that when you use a link to go from the
                        Services to another website, our Privacy Policy is no longer in effect. Your browsing and
                        interaction on any other website, including those that have a link on our platform, is subject to
                        that website&#8217;s own rules and policies. Such third parties may use their own cookies or other
                        methods to collect information about you. If You click on a third party link, You will be directed
                        to that third party's site. We strongly advise You to review the Privacy Policy and Terms of every
                        site You visit. </p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
                        any third party sites or services. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Errors and Omissions Disclaimer </h3>Pet Republic is not responsible for any content, code or any
                        other imprecision. </p>
                    <p>Pet Republic does not provide warranties or guarantees. </p>
                    <p>In no event shall Pet Republic be liable for any special, direct, indirect, consequential, or incidental
                        damages or any damages whatsoever, whether in an action of contract, negligence or other tort,
                        arising out of or in connection with the use of the Service or the contents of the Service. Pet
                        Republic reserves the right to make additions, deletions, or modifications to the contents on the
                        Service at any time without prior notice. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>General Disclaimer </h3>The Pet Republic Service and its contents are provided &quot;as is&quot;
                        and &quot;as available&quot; without any warranty or representations of any kind, whether express or
                        implied. Pet Republic is a distributor and not a publisher of the content supplied by third parties; as
                        such, Pet Republic exercises no editorial control over such content and makes no warranty or
                        representation as to the accuracy, reliability or currency of any information, content, service or
                        merchandise provided through or accessible via the Pet Republic Service. Without limiting the
                        foregoing, Pet Republic specifically disclaims all warranties and representations in any
                        content transmitted on or in connection with the Pet Republic Service or on sites that may appear as
                        links on the Pet Republic Service, or in the products provided as a part of, or otherwise in connection
                        with, the Pet Republic Service, including without limitation any warranties of merchantability, fitness
                        for a particular purpose or non-infringement of third party rights. No oral advice or written
                        information given by Pet Republic or any of its affiliates, employees, officers, directors, agents, or
                        the like will create a warranty. Price and availability information is subject to change without
                        notice. Without limiting the foregoing, Pet Republic does not warrant that the Pet Republic Service
                        will be uninterrupted, uncorrupted, timely, or error-free. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Copyright Disclaimer </h3>All intellectual property rights concerning these materials are vested in
                        Pet Republic. Copying, distribution and any other use of these materials is not permitted without the
                        written permission of Pet Republic, except and only to the extent otherwise provided in regulations of
                        mandatory law (such as the right to quote), unless otherwise stated for certain materials. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Affiliate Links Disclosure </h3>Pet Republic has affiliate links and in this section of the
                        Disclaimer we will address how we use those affiliate links from other websites/companies and products.
                        These &#8220;affiliate links&#8221; are specific URLs that contain the</p>

                </div>
            </div>
            <div>
                <div>
                    <p>affiliate's ID or username. </p>
                    <p>In compliance with the FTC guidelines, please assume the following about links and posts on this
                        site: </p>
                    <p>&#9679; Any/all of the links on Pet Republic are affiliate links of which we receive a small commission
                        from sales of certain items, but the price is the same for you. As Pet Republic has grown, so have
                        costs associated with running and maintaining it, and affiliate links are a way we help offset
                        these costs. </p>
                    <p>&#9679; If we post an affiliate link to a product, it is something that we personally use, support and
                        would recommend without an affiliate link. </p>
                    <p>&#9679; Unless otherwise noted, all reviews are of items we have purchased and we are not paid
                        or compensated in any way. </p>
                    <p>We might participate in affiliate programs such as: </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Health Disclosure </h3>The information on this website such as text, graphics, images, and other
                        materials created by Pet Republic or obtained from Pet Republic licensors, and other materials
                        contained on Pet Republic (collectively, &#8220;content&#8221;) is intended solely for informational
                        purposes and may not be used as a substitute for professional advice and/or information, as
                        circumstances will vary from person to person. You should not act or rely upon this information without
                        seeking professional advice. Do not attempt any of the suggested actions, solutions, remedies, or
                        instructions found on this website without first consulting with a qualified professional.
                        The materials are not intended to be nor do they constitute actionable professional advice.
                        Transmissions of this information is not intended to create a professional-client relationship between
                        Pet Republic and you. The owners, editors, contributors, administrators, and other staff of Pet
                        Republic are not qualifies professionals, and are simply aggregating information found online for
                        informational purposes only. </p>
                    <p>If you think you may have a medical emergency, call your doctor or 911 immediately. Pet Republic does
                        not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other
                        information that may be mentioned on the website. Reliance on any information provided by Pet
                        Republic, Pet Republic employees, others appearing on the website at the invitation of Pet
                        Republic, or other visitors to the website is solely at your own risk. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Testimonials Disclosure </h3>Any testimonials provided on this platform are opinions of those
                        providing them. The information provided in the testimonials is not to be relied upon to predict
                        results in your specific situation. The results you experience will be dependent on many factors
                        including but not limited to your level of personal responsibility, commitment, and abilities, in
                        addition to those factors that you and/or Pet Republic may not be able to anticipate. </p>
                    <p>We will give honest testimonials to our visitors regardless of any discount. Any product or service that
                        we test are individual experiences, reflecting real life experiences. The testimonials could be
                        displayed on audio, text or video and are not necessarily representative of all of those who will
                        use our products and/or services. </p>
                    <p>Pet Republic does not guarantee the same results as the testimonials given on our platform.
                        Testimonials presented on Pet Republic are applicable to the individuals writing them, and may not
                        be indicative of future success of any other individuals. </p>
                    <p>Please don&#8217;t hesitate to contact us if you would like to know more about testimonials, discounts,
                        or any of the products/services that we review. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Your Consent</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>We've updated our Disclaimer to provide you with complete transparency into what is being set when
                        you visit our site and how it's being used. By using our website, registering an account, or making
                        a purchase, you hereby consent to our Disclaimer and agree to its terms. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Changes To Our Disclaimer </h3>Should we update, amend or make any changes to this document so that
                        they accurately reflect our Service and policies. Unless otherwise required by law, those changes will
                        be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated
                        Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your
                        account by contacting us at [ info@petrepublic.ae].
                         </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Contact Us </h3>Don't hesitate to contact us if you have any questions regarding this
                        Disclaimer. </p>
                    <p>&#9679; Via Email: info@petrepublic.ae </p>
                    <p>Powered by TCPDF (www.tcpdf.org)</p>

                </div>
            </div>
            <div>
                <div>
                    <div className={`${styles["main-heading"]}`}>
                        <h2>TERMS &amp; CONDITIONS</h2>
                         
                        Updated at November 29th, 2023
                    </div>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>General Terms </h3>By accessing and placing an order with Pet Republic, you confirm that you are in
                        agreement with and bound by the terms of service contained in the Terms &amp; Conditions outlined
                        below. These terms apply to the entire website and any email or other type of communication between you
                        and Pet Republic. </p>
                    <p>Under no circumstances shall Pet Republic team be liable for any direct, indirect, special, incidental
                        or consequential damages, including, but not limited to, loss of data or profit, arising out of the
                        use, or the inability to use, the materials on this site, even if Pet Republic team or an
                        authorized representative has been advised of the possibility of such damages. If your use of
                        materials from this site results in the need for servicing, repair or correction of equipment or
                        data, you assume any costs thereof. </p>
                    <p>Pet Republic will not be responsible for any outcome that may occur during the course of usage of
                        our resources. We reserve the rights to change prices and revise the resources usage policy in any
                        moment. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>License </h3>Pet Republic grants you a revocable, non-exclusive, non-transferable, limited license
                        to download, install and use the website strictly in accordance with the terms of this Agreement. 
                    </p>
                    <p>These Terms &amp; Conditions are a contract between you and Pet Republic (referred to in these Terms
                        &amp; Conditions as &quot;Pet Republic&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;),
                        the provider of the Pet Republic website and the services accessible from the Pet Republic website
                        (which are collectively referred to in these Terms &amp; Conditions as the &quot;Pet Republic
                        Service&quot;). </p>
                    <p>You are agreeing to be bound by these Terms &amp; Conditions. If you do not agree to these Terms
                        &amp; Conditions, please do not use the Pet Republic Service. In these Terms &amp; Conditions,
                        &quot;you&quot; refers both to you as an individual and to the entity you represent. If you violate
                        any of these Terms &amp; Conditions, we reserve the right to cancel your account or block access to
                        your account without notice. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Definitions and key terms </h3>To help explain things as clearly as possible in this Terms &amp;
                        Conditions, every time any of these terms are referenced, are strictly defined as: </p>
                    <p>&#9679; Cookie: small amount of data generated by a website and saved by your web browser. It is used
                        to identify your browser, provide analytics, remember information about you such as your
                        language preference or login information. </p>
                    <p>&#9679; Company: when this terms mention &#8220;Company,&#8221; &#8220;we,&#8221; &#8220;us,&#8221; or
                        &#8220;our,&#8221; it refers to Pet Republic Portal LLC, (Hamdan Innovation Incubator (Hi2) 221 Al
                        Maktoum Rd - Port Saeed - Dubai), that is responsible for your information under this Terms &amp;
                        Conditions.</p>

                </div>
            </div>
            <div>
                <div>
                    <p>&#9679; Country: where Pet Republic or the owners/founders of Pet Republic are based, in this case is
                        United Arab Emirates </p>
                    <p>&#9679; Device: any internet connected device such as a phone, tablet, computer or any other device that
                        can be used to visit Pet Republic and use the services. </p>
                    <p>&#9679; Service: refers to the service provided by Pet Republic as described in the relative terms (if
                        available) and on this platform. </p>
                    <p>&#9679; Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners,
                        and others who provide our content or whose products or services we think may interest you. 
                    </p>
                    <p>&#9679; Website: Pet Republic&#8217;s site, which can be accessed via this URL:
                        https://www.petrepublic.ae/ &#9679; You: a person or entity that is registered with Pet Republic to
                        use the Services. </p>
                    <p>This Terms &amp; Conditions were created with Termify. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Restrictions </h3>You agree not to, and you will not permit others to: </p>
                    <p>&#9679; License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or
                        otherwise commercially exploit the website or make the platform available to any third party. 
                    </p>
                    <p>&#9679; Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any
                        part of the website. </p>
                    <p>&#9679; Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark)
                        of Pet Republic or its affiliates, partners, suppliers or the licensors of the website. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Payment </h3>If you register to any of our recurring payment plans, you agree to pay all fees or
                        charges to your account for the Service in accordance with the fees, charges and billing terms in
                        effect at the time that each fee or charge is due and payable. Unless otherwise indicated in an order
                        form, you must provide Pet Republic with a valid credit card (Visa, MasterCard, or any other issuer
                        accepted by us) (&#8220;Payment Provider&#8221;) as a condition to signing up for the Premium plan.
                        Your Payment Provider agreement governs your use of the designated credit card account, and you must
                        refer to that agreement and not these Terms to determine your rights and liabilities with respect to
                        your Payment Provider. By providing Pet Republic with your credit card number and associated payment
                        information, you agree that Pet Republic is authorized to verify information immediately, and
                        subsequently invoice your account for all fees and charges due and payable to Pet Republic hereunder
                        and that no additional notice or consent is required. You agree to immediately notify Pet Republic of
                        any change in your billing address or the credit card used for payment hereunder. Pet Republic reserves
                        the right at any time to change its prices and billing methods, either immediately upon posting on our
                        Site or by e-mail delivery to your organization&#8217;s administrator(s). </p>
                    <p>Any attorney fees, court costs, or other costs incurred in collection of delinquent undisputed amounts
                        shall be the responsibility of and paid for by you. </p>
                    <p>No contract will exist between you and Pet Republic for the Service until Pet Republic accepts your order
                        by a confirmatory e-mail, SMS/MMS message, or other appropriate means of communication. </p>
                    <p>You are responsible for any third-party fees that you may incur when using the Service. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Return and Refund Policy</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>Thanks for shopping at Pet Republic. We appreciate the fact that you like to buy the stuff we build. We
                        also want to make sure you have a rewarding experience while you&#8217;re exploring, evaluating,
                        and purchasing our products. </p>
                    <p>As with any shopping experience, there are terms and conditions that apply to transactions at Pet
                        Republic. We&#8217;ll be as brief as our attorneys will allow. The main thing to remember is that
                        by placing an order or making a purchase at Pet Republic, you agree to the terms along with Pet
                        Republic&#8217;s Privacy Policy. </p>
                    <p>If, for any reason, You are not completely satisfied with any good or service that we provide, don't
                        hesitate to contact us and we will discuss any of the issues you are going through with our
                        product. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Your Suggestions </h3>Any feedback, comments, ideas, improvements or suggestions (collectively,
                        &quot;Suggestions&quot;) provided by you to Pet Republic with respect to the website shall remain the
                        sole and exclusive property of Pet Republic. </p>
                    <p>Pet Republic shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose
                        and in any way without any credit or any compensation to you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Your Consent </h3>We've updated our Terms &amp; Conditions to provide you with complete
                        transparency into what is being set when you visit our site and how it's being used. By using our
                        website, registering an account, or making a purchase, you hereby consent to our Terms &amp;
                        Conditions. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Links to Other Websites </h3>This Terms &amp; Conditions applies only to the Services. The Services
                        may contain links to other websites not operated or controlled by Pet Republic. We are not responsible
                        for the content, accuracy or opinions expressed in such websites, and such websites are not
                        investigated, monitored or checked for accuracy or completeness by us. Please remember that when you
                        use a link to go from the Services to another website, our Terms &amp; Conditions are no longer in
                        effect. Your browsing and interaction on any other website, including those that have a link on our
                        platform, is subject to that website&#8217;s own rules and policies. Such third parties may use their
                        own cookies or other methods to collect information about you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Cookies </h3>Pet Republic uses &quot;Cookies&quot; to identify the areas of our website that you
                        have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web
                        browser. We use Cookies to enhance the performance and functionality of our website but are
                        non-essential to their use. However, without these cookies, certain functionality like videos may
                        become unavailable or you would be required to enter your login details every time you visit the
                        website as we would not be able to remember that you had logged in previously. Most web browsers can be
                        set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access
                        functionality on our website correctly or at all. We never place Personally Identifiable Information in
                        Cookies. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Changes To Our Terms &amp; Conditions</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>You acknowledge and agree that Pet Republic may stop (permanently or temporarily) providing the
                        Service (or any features within the Service) to you or to users generally at Pet Republic&#8217;s
                        sole discretion, without prior notice to you. You may stop using the Service at any time. You do
                        not need to specifically inform Pet Republic when you stop using the Service. You acknowledge and
                        agree that if Pet Republic disables access to your account, you may be prevented from accessing the
                        Service, your account details or any files or other materials which is contained in your
                        account. </p>
                    <p>If we decide to change our Terms &amp; Conditions, we will post those changes on this page, and/or update
                        the Terms &amp; Conditions modification date below. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Modifications to Our website </h3>Pet Republic reserves the right to modify, suspend or
                        discontinue, temporarily or permanently, the website or any service to which it connects, with or
                        without notice and without liability to you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Updates to Our website </h3>Pet Republic may from time to time provide enhancements or improvements
                        to the features/ functionality of the website, which may include patches, bug fixes, updates, upgrades
                        and other modifications (&quot;Updates&quot;). </p>
                    <p>Updates may modify or delete certain features and/or functionalities of the website. You agree that
                        Pet Republic has no obligation to (i) provide any Updates, or (ii) continue to provide or enable
                        any particular features and/or functionalities of the website to you. </p>
                    <p>You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and
                        (ii) subject to the terms and conditions of this Agreement. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Third-Party Services </h3>We may display, include or make available third-party content (including
                        data, information, applications and other products services) or provide links to third-party websites
                        or services (&quot;Third- Party Services&quot;). </p>
                    <p>You acknowledge and agree that Pet Republic shall not be responsible for any Third-Party
                        Services, including their accuracy, completeness, timeliness, validity, copyright compliance,
                        legality, decency, quality or any other aspect thereof. Pet Republic does not assume and shall not
                        have any liability or responsibility to you or any other person or entity for any Third-Party
                        Services. </p>
                    <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and
                        use them entirely at your own risk and subject to such third parties' terms and conditions. 
                    </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Term and Termination </h3>This Agreement shall remain in effect until terminated by you or Pet
                        Republic.</p>

                </div>
            </div>
            <div>
                <div>
                    <p>Pet Republic may, in its sole discretion, at any time and for any or no reason, suspend or terminate
                        this Agreement with or without prior notice. </p>
                    <p>This Agreement will terminate immediately, without prior notice from Pet Republic, in the event that you
                        fail to comply with any provision of this Agreement. You may also terminate this Agreement by
                        deleting the website and all copies thereof from your computer. </p>
                    <p>Upon termination of this Agreement, you shall cease all use of the website and delete all copies of
                        the website from your computer. </p>
                    <p>Termination of this Agreement will not limit any of Pet Republic's rights or remedies at law or in equity
                        in case of breach by you (during the term of this Agreement) of any of your obligations under the
                        present Agreement. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Copyright Infringement Notice </h3>If you are a copyright owner or such owner&#8217;s agent and
                        believe any material on our website constitutes an infringement on your copyright, please contact us
                        setting forth the following information: (a) a physical or electronic signature of the copyright owner
                        or a person authorized to act on his behalf; (b) identification of the material that is claimed to be
                        infringing; (c) your contact information, including your address, telephone number, and an email; (d) a
                        statement by you that you have a good faith belief that use of the material is not authorized by the
                        copyright owners; and (e) the a statement that the information in the notification is accurate, and,
                        under penalty of perjury you are authorized to act on behalf of the owner. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Indemnification </h3>You agree to indemnify and hold Pet Republic and its parents, subsidiaries,
                        affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or
                        demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the website;
                        (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third
                        party. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>No Warranties </h3>The website is provided to you &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                        and with all faults and defects without warranty of any kind. To the maximum extent permitted under
                        applicable law, Pet Republic, on its own behalf and on behalf of its affiliates and its and their
                        respective licensors and service providers, expressly disclaims all warranties, whether express,
                        implied, statutory or otherwise, with respect to the website, including all implied warranties of
                        merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may
                        arise out of course of dealing, course of performance, usage or trade practice. Without limitation to
                        the foregoing, Pet Republic provides no warranty or undertaking, and makes no representation of any
                        kind that the website will meet your requirements, achieve any intended results, be compatible or work
                        with any other software, websites, systems or services, operate without interruption, meet any
                        performance or reliability standards or be error free or that any errors or defects can or will be
                        corrected. </p>
                    <p>Without limiting the foregoing, neither Pet Republic nor any Pet Republic's provider makes
                        any representation or warranty of any kind, express or implied: (i) as to the operation or
                        availability of the website, or the information, content, and materials or products included
                        thereon; (ii) that the website will be uninterrupted or error-free; (iii) as to the accuracy,
                        reliability, or currency of any information or content provided through the website; or (iv) that
                        the website, its servers, the content, or e-mails sent from or on behalf of Pet Republic are free
                        of viruses, scripts, trojan horses, worms, malware, timebombs or other</p>

                </div>
            </div>
            <div>
                <div>
                    <p>harmful components. </p>
                    <p>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations
                        on the applicable statutory rights of a consumer, so some or all of the above exclusions and
                        limitations may not apply to you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Limitation of Liability </h3>Notwithstanding any damages that you might incur, the entire liability
                        of Pet Republic and any of its suppliers under any provision of this Agreement and your exclusive
                        remedy for all of the foregoing shall be limited to the amount actually paid by you for the
                        website. </p>
                    <p>To the maximum extent permitted by applicable law, in no event shall Pet Republic or its suppliers be
                        liable for any special, incidental, indirect, or consequential damages whatsoever (including, but
                        not limited to, damages for loss of profits, for loss of data or other information, for business
                        interruption, for personal injury, for loss of privacy arising out of or in any way related to the
                        use of or inability to use the website, third-party software and/or third-party hardware used with
                        the website, or otherwise in connection with any provision of this Agreement), even if Pet Republic
                        or any supplier has been advised of the possibility of such damages and even if the remedy fails of
                        its essential purpose. </p>
                    <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential
                        damages, so the above limitation or exclusion may not apply to you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Severability </h3>If any provision of this Agreement is held to be unenforceable or invalid, such
                        provision will be changed and interpreted to accomplish the objectives of such provision to the
                        greatest extent possible under applicable law and the remaining provisions will continue in full force
                        and effect. </p>
                    <p>This Agreement, together with the Privacy Policy and any other legal notices published by Pet Republic
                        on the Services, shall constitute the entire agreement between you and Pet Republic concerning the
                        Services. If any provision of this Agreement is deemed invalid by a court of competent
                        jurisdiction, the invalidity of such provision shall not affect the validity of the remaining
                        provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of
                        this Agreement shall be deemed a further or continuing waiver of such term or any other term, and
                        Pet Republic&#8217;s failure to assert any right or provision under this Agreement shall not
                        constitute a waiver of such right or provision. YOU AND Pet Republic AGREE THAT ANY CAUSE OF ACTION
                        ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                        ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Waiver </h3>Except as provided herein, the failure to exercise a right or to require performance of
                        an obligation under this Agreement shall not effect a party's ability to exercise such right or require
                        such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any
                        subsequent breach. </p>
                    <p>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
                        under this Agreement shall operate as a waiver of that right or power. Nor shall any single or
                        partial exercise of any right or power under this Agreement preclude further exercise of that or
                        any other right granted herein. In the</p>

                </div>
            </div>
            <div>
                <div>
                    <p>event of a conflict between this Agreement and any applicable purchase or other terms, the terms of
                        this Agreement shall govern. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Amendments to this Agreement </h3>Pet Republic reserves the right, at its sole discretion, to
                        modify or replace this Agreement at any time. If a revision is material we will provide at least 30
                        days' notice prior to any new terms taking effect. What constitutes a material change will be
                        determined at our sole discretion. </p>
                    <p>By continuing to access or use our website after any revisions become effective, you agree to be bound
                        by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Pet
                        Republic. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Entire Agreement </h3>The Agreement constitutes the entire agreement between you and Pet Republic
                        regarding your use of the website and supersedes all prior and contemporaneous written or oral
                        agreements between you and Pet Republic. </p>
                    <p>You may be subject to additional terms and conditions that apply when you use or purchase other
                        Pet Republic's services, which Pet Republic will provide to you at the time of such use or
                        purchase. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Updates to Our Terms </h3>We may change our Service and policies, and we may need to make changes
                        to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by
                        law, we will notify you (for example, through our Service) before we make changes to these Terms and
                        give you an opportunity to review them before they go into effect. Then, if you continue to use the
                        Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated
                        Terms, you can delete your account by contacting us at [ info@petrepublic.ae]. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Intellectual Property </h3>The website and its entire contents, features and functionality
                        (including but not limited to all information, software, text, displays, images, video and audio, and
                        the design, selection and arrangement thereof), are owned by Pet Republic, its licensors or other
                        providers of such material and are protected by United Arab Emirates and international copyright,
                        trademark, patent, trade secret and other intellectual property or proprietary rights laws. The
                        material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in
                        part, without the express prior written permission of Pet Republic, unless and except as is expressly
                        provided in these Terms &amp; Conditions. Any unauthorized use of the material is prohibited. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Agreement to Arbitrate </h3>This section applies to any dispute EXCEPT IT DOESN&#8217;T INCLUDE A
                        DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF
                        YOUR OR Pet Republic&#8217;s INTELLECTUAL PROPERTY RIGHTS. The term &#8220;dispute&#8221; means any
                        dispute, action, or</p>

                </div>
            </div>
            <div>
                <div>
                    <p>other controversy between you and Pet Republic concerning the Services or this agreement, whether
                        in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis.
                        &#8220;Dispute&#8221; will be given the broadest possible meaning allowable under law. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Notice of Dispute </h3>In the event of a dispute, you or Pet Republic must give the other a Notice
                        of Dispute, which is a written statement that sets forth the name, address, and contact information of
                        the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any
                        Notice of Dispute via email to: info@petrepublic.ae. Pet Republic will send any Notice of Dispute to
                        you by mail to your address if we have it, or otherwise to your email address. You and Pet Republic
                        will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date
                        the Notice of Dispute is sent. After sixty (60) days, you or Pet Republic may commence
                        arbitration. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Binding Arbitration </h3>If you and Pet Republic don&#8217;t resolve any dispute by informal
                        negotiation, any other effort to resolve the dispute will be conducted exclusively by binding
                        arbitration as described in this section. You are giving up the right to litigate (or participate in as
                        a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by
                        binding arbitration in accordance with the commercial arbitration rules of the American Arbitration
                        Association. Either party may seek any interim or preliminary injunctive relief from any court of
                        competent jurisdiction, as necessary to protect the party&#8217;s rights or property pending the
                        completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred
                        by the prevailing party shall be borne by the non-prevailing party. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Submissions and Privacy </h3>In the event that you submit or post any ideas, creative suggestions,
                        designs, photographs, information, advertisements, data or proposals, including ideas for new or
                        improved products, services, features, technologies or promotions, you expressly agree that such
                        submissions will automatically be treated as non-confidential and non-proprietary and will become the
                        sole property of Pet Republic without any compensation or credit to you whatsoever. Pet Republic and
                        its affiliates shall have no obligations with respect to such submissions or posts and may use the
                        ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including,
                        but not limited to, developing, manufacturing, and marketing products and services using such
                        ideas. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Promotions </h3>Pet Republic may, from time to time, include contests, promotions, sweepstakes, or
                        other activities (&#8220;Promotions&#8221;) that require you to submit material or information
                        concerning yourself. Please note that all Promotions may be governed by separate rules that may contain
                        certain eligibility requirements, such as restrictions as to age and geographic location. You are
                        responsible to read all Promotions rules to determine whether or not you are eligible to participate.
                        If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules. </p>
                    <p>Additional terms and conditions may apply to purchases of goods or services on or through the
                        Services, which terms and conditions are made a part of this Agreement by this reference. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Typographical Errors</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>In the event a product and/or service is listed at an incorrect price or with incorrect information due
                        to typographical error, we shall have the right to refuse or cancel any orders placed for the
                        product and/or service listed at the incorrect price. We shall have the right to refuse or cancel
                        any such order whether or not the order has been confirmed and your credit card charged. If your
                        credit card has already been charged for the purchase and your order is canceled, we shall
                        immediately issue a credit to your credit card account or other payment account in the amount of
                        the charge. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Miscellaneous </h3>If for any reason a court of competent jurisdiction finds any provision or
                        portion of these Terms &amp; Conditions to be unenforceable, the remainder of these Terms &amp;
                        Conditions will continue in full force and effect. Any waiver of any provision of these Terms &amp;
                        Conditions will be effective only if in writing and signed by an authorized representative of Pet
                        Republic. Pet Republic will be entitled to injunctive or other equitable relief (without the
                        obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you.
                        Pet Republic operates and controls the Pet Republic Service from its offices in United Arab
                        Emirates. The Service is not intended for distribution to or use by any person or entity in any
                        jurisdiction or country where such distribution or use would be contrary to law or regulation.
                        Accordingly, those persons who choose to access the Pet Republic Service from other locations do so on
                        their own initiative and are solely responsible for compliance with local laws, if and to the extent
                        local laws are applicable. These Terms &amp; Conditions (which include and incorporate the Pet Republic
                        Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you
                        and Pet Republic concerning its subject matter, and cannot be changed or modified by you. The section
                        headings used in this Agreement are for convenience only and will not be given any legal import. 
                    </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Disclaimer </h3>Pet Republic is not responsible for any content, code or any other
                        imprecision. </p>
                    <p>Pet Republic does not provide warranties or guarantees. </p>
                    <p>In no event shall Pet Republic be liable for any special, direct, indirect, consequential, or incidental
                        damages or any damages whatsoever, whether in an action of contract, negligence or other tort,
                        arising out of or in connection with the use of the Service or the contents of the Service. The
                        Company reserves the right to make additions, deletions, or modifications to the contents on the
                        Service at any time without prior notice. </p>
                    <p>The Pet Republic Service and its contents are provided &quot;as is&quot; and &quot;as available&quot;
                        without any warranty or representations of any kind, whether express or implied. Pet Republic is a
                        distributor and not a publisher of the content supplied by third parties; as such, Pet Republic
                        exercises no editorial control over such content and makes no warranty or representation as to the
                        accuracy, reliability or currency of any information, content, service or merchandise provided
                        through or accessible via the Pet Republic Service. Without limiting the foregoing, Pet Republic
                        specifically disclaims all warranties and representations in any content transmitted on or in
                        connection with the Pet Republic Service or on sites that may appear as links on the Pet Republic
                        Service, or in the products provided as a part of, or otherwise in connection with, the Pet
                        Republic Service, including without limitation any warranties of merchantability, fitness for a
                        particular purpose or non-infringement of third party rights. No oral advice or written information
                        given by Pet Republic or any of its affiliates, employees, officers, directors, agents, or the like
                        will create a warranty. Price and availability information is subject to change without notice.
                        Without limiting the foregoing, Pet Republic does not warrant that the Pet Republic Service will be
                        uninterrupted, uncorrupted, timely, or error-free. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Contact Us</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>Don't hesitate to contact us if you have any questions. </p>
                    <p>&#9679; Via Email: info@petrepublic.ae </p>
                    <p>Powered by TCPDF (www.tcpdf.org)</p>

                </div>
            </div>
            <div>
                <div>
                    <div className={`${styles["main-heading"]}`}>
                        <h2>PRIVACY POLICY</h2>
                         
                        Updated at November 29th, 2023
                    </div>
                    <p>Pet Republic (&#8220;we,&#8221; &#8220;our,&#8221; or &#8220;us&#8221;) is committed to protecting your
                        privacy. This Privacy Policy explains how your personal information is collected, used, and
                        disclosed by Pet Republic. </p>
                    <p>This Privacy Policy applies to our website, and its associated subdomains (collectively, our
                        &#8220;Service&#8221;) alongside our application, Pet Republic. By accessing or using our Service,
                        you signify that you have read, understood, and agree to our collection, storage, use, and
                        disclosure of your personal information as described in this Privacy Policy and our Terms of
                        Service. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Definitions and key terms </h3>To help explain things as clearly as possible in this Privacy
                        Policy, every time any of these terms are referenced, are strictly defined as: </p>
                    <p>&#9679; Cookie: small amount of data generated by a website and saved by your web browser. It is used
                        to identify your browser, provide analytics, remember information about you such as your
                        language preference or login information. </p>
                    <p>&#9679; Company: when this policy mentions &#8220;Company,&#8221; &#8220;we,&#8221; &#8220;us,&#8221; or
                        &#8220;our,&#8221; it refers to Pet Republic Portal LLC, (Hamdan Innovation Incubator (Hi2)221 Al
                        Maktoum Rd - Port Saeed - Dubai), that is responsible for your information under this Privacy
                        Policy. </p>
                    <p>&#9679; Country: where Pet Republic or the owners/founders of Pet Republic are based, in this case is
                        United Arab Emirates </p>
                    <p>&#9679; Customer: refers to the company, organization or person that signs up to use the Pet Republic
                        Service to manage the relationships with your consumers or service users. </p>
                    <p>&#9679; Device: any internet connected device such as a phone, tablet, computer or any other device that
                        can be used to visit Pet Republic and use the services. </p>
                    <p>&#9679; IP address: Every device connected to the Internet is assigned a number known as an Internet
                        protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can
                        often be used to identify the location from which a device is connecting to the Internet. </p>
                    <p>&#9679; Personnel: refers to those individuals who are employed by Pet Republic or are under contract
                        to perform a service on behalf of one of the parties. </p>
                    <p>&#9679; Personal Data: any information that directly, indirectly, or in connection with other information
                        &#8212; including a personal identification number &#8212; allows for the identification or
                        identifiability of a natural person. </p>
                    <p>&#9679; Service: refers to the service provided by Pet Republic as described in the relative terms (if
                        available) and on this platform. </p>
                    <p>&#9679; Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners,
                        and others who provide our content or whose products or services we think may interest you. 
                    </p>
                    <p>&#9679; Website: Pet Republic&#8217;s site, which can be accessed via this URL:
                        https://www.petrepublic.ae/ &#9679; You: a person or entity that is registered with Pet Republic to
                        use the Services. </p>
                    <p>This Privacy Policy was created with Termify.</p>

                </div>
            </div>
            <div>
                <div>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>What Information Do We Collect? </h3>We collect information from you when you visit our website,
                        register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a
                        form. </p>
                    <p>&#9679; Name / Username &#9679; Phone Numbers &#9679; Email Addresses &#9679; Mailing
                        Addresses &#9679; Age </p>
                    <p>We also collect information from mobile devices for a better user experience, although these features
                        are completely optional: </p>
                    <p>&#9679; Location (GPS): Location data helps to create an accurate representation of your interests, and
                        this can be used to bring more targeted and relevant ads to potential customers. </p>
                    <p>&#9679; Photo Gallery (Pictures): Granting photo gallery access allows the user to upload any picture
                        from their photo gallery, you can safely deny photo gallery access for this platform. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>When does Pet Republic use end user information from third parties? </h3>Pet Republic will
                        collect End User Data necessary to provide the Pet Republic services to our customers. End users may
                        voluntarily provide us with information they have made available on social media websites. If you
                        provide us with any such information, we may collect publicly available information from the social
                        media websites you have indicated. You can control how much of your information social media websites
                        make public by visiting these websites and changing your privacy settings. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>When does Pet Republic use customer information from third parties? </h3>We receive some
                        information from the third parties when you contact us. For example, when you submit your email address
                        to us to show interest in becoming a Pet Republic customer, we receive information from a third party
                        that provides automated fraud detection services to Pet Republic. We also occasionally
                        collect information that is made publicly available on social media websites. You can control how much
                        of your information social media websites make public by visiting these websites and changing your
                        privacy settings. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Do we share the information we collect with third parties? </h3>We may share the information
                        that we collect, both personal and non-personal, with third parties such as</p>

                </div>
            </div>
            <div>
                <div>
                    <p>advertisers, contest sponsors, promotional and marketing partners, and others who provide our content
                        or whose products or services we think may interest you. We may also share it with our current and
                        future affiliated companies and business partners, and if we are involved in a merger, asset sale
                        or other business reorganization, we may also share or transfer your personal and non-personal
                        information to our successors-in-interest. </p>
                    <p>We may engage trusted third party service providers to perform functions and provide services to us, such
                        as hosting and maintaining our servers and the website, database storage and management,
                        e-mail management, storage marketing, credit card processing, customer service and fulfilling
                        orders for products and services you may purchase through the website. We will likely share your
                        personal information, and possibly some non-personal information, with these third parties to
                        enable them to perform these services for us and for you. </p>
                    <p>We may share portions of our log file data, including IP addresses, for analytics purposes with third
                        parties such as web analytics partners, application developers, and ad networks. If your IP address
                        is shared, it may be used to estimate general location and other technographics such as connection
                        speed, whether you have visited the website in a shared location, and type of the device used to
                        visit the website. They may aggregate information about our advertising and what you see on the
                        website and then provide auditing, research and reporting for us and our advertisers. </p>
                    <p>We may also disclose personal and non-personal information about you to government or law
                        enforcement officials or private parties as we, in our sole discretion, believe necessary or
                        appropriate in order to respond to claims, legal process (including subpoenas), to protect our
                        rights and interests or those of a third party, the safety of the public or any person, to prevent
                        or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with
                        applicable court orders, laws, rules and regulations. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Where and when is information collected from customers and end users? </h3>Pet Republic will
                        collect personal information that you submit to us. We may also receive personal information about you
                        from third parties as described above. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>How Do We Use The Information We Collect? </h3>Any of the information we collect from you may be
                        used in one of the following ways: </p>
                    <p>&#9679; To personalize your experience (your information helps us to better respond to your individual
                        needs) &#9679; To improve our website (we continually strive to improve our website offerings based
                        on the information </p>
                    <p>and feedback we receive from you) &#9679; To improve customer service (your information helps us to
                        more effectively respond to your customer </p>
                    <p>service requests and support needs) &#9679; To process transactions &#9679; To administer a
                        contest, promotion, survey or other site feature &#9679; To send periodic emails </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>How Do We Use Your Email Address?</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>By submitting your email address on this website, you agree to receive emails from us. You can cancel
                        your participation in any of these email lists at any time by clicking on the opt-out link or other&nbsp; 
                        {/* <Link to="/unsubscribe" className="underline text-blue-700">unsubscribe</Link> option that is included in the respective email. We only send emails to people who have */}
                        unsubscribe option that is included in the respective email. We only send emails to people who have
                        authorized us to contact them, either directly, or through a third party. We do not send
                        unsolicited commercial emails, because we hate spam as much as you do. By submitting your email
                        address, you also agree to allow us to use your email address for customer audience targeting on
                        sites like Facebook, where we display custom advertising to specific people who have opted-in to
                        receive communications from us. Email addresses submitted only through the order processing page
                        will be used for the sole purpose of sending you information and updates pertaining to your order.
                        If, however, you have provided the same email to us through another method, we may use it for any
                        {/* of the purposes stated in this Policy. Note: If at any time you would like to <Link to="/unsubscribe" className="underline text-blue-700">unsubscribe</Link> from
                        receiving future emails, we include detailed <Link to="/unsubscribe" className="underline text-blue-700">unsubscribe</Link> instructions at the bottom of each email.  */}
                        of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from
                        receiving future emails, we include detailed unsubscribe instructions at the bottom of each email. 
                    </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>How Long Do We Keep Your Information? </h3>We keep your information only so long as we need it to
                        provide Pet Republic to you and fulfill the purposes described in this policy. This is also the case
                        for anyone that we share your information with and who carries out services on our behalf. When we no
                        longer need to use your information and there is no need for us to keep it to comply with our legal or
                        regulatory obligations, we&#8217;ll either remove it from our systems or depersonalize it so that we
                        can't identify you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>How Do We Protect Your Information? </h3>We implement a variety of security measures to maintain
                        the safety of your personal information when you place an order or enter, submit, or access your
                        personal information. We offer the use of a secure server. All supplied sensitive/credit information is
                        transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway
                        providers database only to be accessible by those authorized with special access rights to such
                        systems, and are required to keep the information confidential. After a transaction, your private
                        information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot,
                        however, ensure or warrant the absolute security of any information you transmit to Pet Republic or
                        guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by
                        a breach of any of our physical, technical, or managerial safeguards. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Could my information be transferred to other countries? </h3>Pet Republic is incorporated in
                        United Arab Emirates. Information collected via our website, through direct interactions with you, or
                        from use of our help services may be transferred from time to time to our offices or personnel, or to
                        third parties, located throughout the world, and may be viewed and hosted anywhere in the world,
                        including countries that may not have laws of general applicability regulating the use and transfer
                        of such data. To the fullest extent allowed by applicable law, by using any of the above, you
                        voluntarily consent to the trans-border transfer and hosting of such information. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Is the information collected through the Pet Republic Service secure? </h3>We take precautions
                        to protect the security of your information. We have physical, electronic, and managerial</p>

                </div>
            </div>
            <div>
                <div>
                    <p>procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use
                        your information. However, neither people nor security systems are foolproof, including encryption
                        systems. In addition, people can commit intentional crimes, make mistakes or fail to follow
                        policies. Therefore, while we use reasonable efforts to protect your personal information, we
                        cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to
                        protect your personal information, you agree that intentional misconduct will be the standards used
                        to measure our compliance with that duty. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Can I update or correct my information? </h3>The rights you have to request updates or corrections
                        to the information Pet Republic collects depend on your relationship with Pet Republic. Personnel may
                        update or correct their information as detailed in our internal company employment policies. </p>
                    <p>Customers have the right to request the restriction of certain uses and disclosures of personally
                        identifiable information as follows. You can contact us in order to (1) update or correct your
                        personally identifiable information, (2) change your preferences with respect to communications and
                        other information you receive from us, or (3) delete the personally identifiable information
                        maintained about you on our systems (subject to the following paragraph), by cancelling your
                        account. Such updates, corrections, changes and deletions will have no effect on other information
                        that we maintain, or information that we have provided to third parties in accordance with this
                        Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and
                        security, we may take reasonable steps (such as requesting a unique password) to verify
                        your identity before granting you profile access or making corrections. You are responsible for
                        maintaining the secrecy of your unique password and account information at all times. </p>
                    <p>You should be aware that it is not technologically possible to remove each and every record of
                        the information you have provided to us from our system. The need to back up our systems to
                        protect information from inadvertent loss means that a copy of your information may exist in a
                        non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving
                        your request, all personal information stored in databases we actively use, and other readily
                        searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to
                        the extent reasonably and technically practicable. </p>
                    <p>If you are an end user and wish to update, delete, or receive any information we have about you, you may
                        do so by contacting the organization of which you are a customer. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Personnel </h3>If you are a Pet Republic worker or applicant, we collect information you
                        voluntarily provide to us. We use the information collected for Human Resources purposes in order to
                        administer benefits to workers and screen applicants. </p>
                    <p>You may contact us in order to (1) update or correct your information, (2) change your preferences
                        with respect to communications and other information you receive from us, or (3) receive a record
                        of the information we have relating to you. Such updates, corrections, changes and deletions will
                        have no effect on other information that we maintain, or information that we have provided to third
                        parties in accordance with this Privacy Policy prior to such update, correction, change or
                        deletion. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Sale of Business </h3>We reserve the right to transfer information to a third party in the event of
                        a sale, merger or other transfer of</p>

                </div>
            </div>
            <div>
                <div>
                    <p>all or substantially all of the assets of Pet Republic or any of its Corporate Affiliates (as defined
                        herein), or that portion of Pet Republic or any of its Corporate Affiliates to which the Service
                        relates, or in the event that we discontinue our business or file a petition or have filed against
                        us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party
                        agrees to adhere to the terms of this Privacy Policy. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Affiliates </h3>We may disclose information (including personal information) about you to our
                        Corporate Affiliates. For purposes of this Privacy Policy, &quot;Corporate Affiliate&quot; means any
                        person or entity which directly or indirectly controls, is controlled by or is under common control
                        with Pet Republic, whether by ownership or otherwise. Any information relating to you that we provide
                        to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms
                        of this Privacy Policy. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Governing Law </h3>This Privacy Policy is governed by the laws of United Arab Emirates without
                        regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in
                        connection with any action or dispute arising between the parties under or in connection with this
                        Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or
                        the Swiss-US framework. </p>
                    <p>The laws of United Arab Emirates, excluding its conflicts of law rules, shall govern this Agreement and
                        your use of the website. Your use of the website may also be subject to other local, state,
                        national, or international laws. </p>
                    <p>By using Pet Republic or contacting us directly, you signify your acceptance of this Privacy Policy. If
                        you do not agree to this Privacy Policy, you should not engage with our website, or use our
                        services. Continued use of the website, direct engagement with us, or following the posting of
                        changes to this Privacy Policy that do not significantly affect the use or disclosure of your
                        personal information will mean that you accept those changes. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Your Consent </h3>We've updated our Privacy Policy to provide you with complete transparency into
                        what is being set when you visit our site and how it's being used. By using our Pet Republic,
                        registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its
                        terms. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Links to Other Websites </h3>This Privacy Policy applies only to the Services. The Services may
                        contain links to other websites not operated or controlled by Pet Republic. We are not responsible for
                        the content, accuracy or opinions expressed in such websites, and such websites are not investigated,
                        monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go
                        from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and
                        interaction on any other website, including those that have a link on our platform, is subject to that
                        website&#8217;s own rules and policies. Such third parties may use their own cookies or other methods
                        to collect information about you. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Cookies</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>Pet Republic uses &quot;Cookies&quot; to identify the areas of our website that you have visited. A
                        Cookie is a small piece of data stored on your computer or mobile device by your web browser. We
                        use Cookies to enhance the performance and functionality of our website but are non-essential to
                        their use. However, without these cookies, certain functionality like videos may become unavailable
                        or you would be required to enter your login details every time you visit the website as we would
                        not be able to remember that you had logged in previously. Most web browsers can be set to disable
                        the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on
                        our website correctly or at all. We never place Personally Identifiable Information in
                        Cookies. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Blocking and disabling cookies and similar technologies </h3>Wherever you're located you may
                        also set your browser to block cookies and similar technologies, but this action may block our
                        essential cookies and prevent our website from functioning properly, and you may not be able to fully
                        utilize all of its features and services. You should also be aware that you may also lose some saved
                        information (e.g. saved login details, site preferences) if you block cookies on your browser.
                        Different browsers make different controls available to you. Disabling a cookie or category of cookie
                        does not delete the cookie from your browser, you will need to do this yourself from within your
                        browser, you should visit your browser's help menu for more information. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Payment Details </h3>In respect to any credit card or other payment processing details you have
                        provided us, we commit that this confidential information will be stored in the most secure manner
                        possible. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Kids' Privacy </h3>We do not address anyone under the age of 13. We do not knowingly collect
                        personally identifiable information from anyone under the age of 13. If You are a parent or guardian
                        and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become
                        aware that We have collected Personal Data from anyone under the age of 13 without verification of
                        parental consent, We take steps to remove that information from Our servers. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Changes To Our Privacy Policy </h3>We may change our Service and policies, and we may need to make
                        changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless
                        otherwise required by law, we will notify you (for example, through our Service) before we make changes
                        to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if
                        you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to
                        agree to this or any updated Privacy Policy, you can delete your account by contacting us at [ info@petrepublic.ae]. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Third-Party Services</h3>
                    </p>

                </div>
            </div>
            <div>
                <div>
                    <p>We may display, include or make available third-party content (including data, information, applications
                        and other products services) or provide links to third-party websites or services (&quot;Third-
                        Party Services&quot;). </p>
                    <p>You acknowledge and agree that Pet Republic shall not be responsible for any Third-Party
                        Services, including their accuracy, completeness, timeliness, validity, copyright compliance,
                        legality, decency, quality or any other aspect thereof. Pet Republic does not assume and shall not
                        have any liability or responsibility to you or any other person or entity for any Third-Party
                        Services. </p>
                    <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and
                        use them entirely at your own risk and subject to such third parties' terms and conditions. 
                    </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Information about General Data Protection Regulation (GDPR) </h3>We may be collecting and
                        using information from you if you are from the European Economic Area (EEA), and in this section of our
                        Privacy Policy we are going to explain exactly how and why is this data collected, and how we maintain
                        this data under protection from being replicated or used in the wrong way. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>What is GDPR? </h3>GDPR is an EU-wide privacy and data protection law that regulates how EU
                        residents' data is protected by companies and enhances the control the EU residents have, over their
                        personal data. </p>
                    <p>The GDPR is relevant to any globally operating company and not just the EU-based businesses and
                        EU residents. Our customers&#8217; data is important irrespective of where they are located, which
                        is why we have implemented GDPR controls as our baseline standard for all our operations
                        worldwide. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>What is personal data? </h3>Any data that relates to an identifiable or identified individual. GDPR
                        covers a broad spectrum of information that could be used on its own, or in combination with other
                        pieces of information, to identify a person. Personal data extends beyond a person&#8217;s name or
                        email address. Some examples include financial information, political opinions, genetic data, biometric
                        data, IP addresses, physical address, sexual orientation, and ethnicity. </p>
                    <p>The Data Protection Principles include requirements such as: </p>
                    <p>&#9679; Personal data collected must be processed in a fair, legal, and transparent way and should only
                        be used in a way that a person would reasonably expect. </p>
                    <p>&#9679; Personal data should only be collected to fulfil a specific purpose and it should only be used
                        for that purpose. Organizations must specify why they need the personal data when they collect
                        it. </p>
                    <p>&#9679; Personal data should be held no longer than necessary to fulfil its purpose. &#9679; People
                        covered by the GDPR have the right to access their own personal data. They can also request a </p>
                    <p>copy of their data, and that their data be updated, deleted, restricted, or moved to another
                        organization.</p>

                </div>
            </div>
            <div>
                <div>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Why is GDPR important? </h3>GDPR adds some new requirements regarding how companies should protect
                        individuals' personal data that they collect and process. It also raises the stakes for compliance by
                        increasing enforcement and imposing greater fines for breach. Beyond these facts it's simply the right
                        thing to do. At Pet Republic we strongly believe that your data privacy is very important and we
                        already have solid security and privacy practices in place that go beyond the requirements of this new
                        regulation. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Individual Data Subject's Rights - Data Access, Portability and Deletion </h3>We are committed
                        to helping our customers meet the data subject rights requirements of GDPR. Pet Republic processes or
                        stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation and
                        personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data
                        in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than
                        60 days. </p>
                    <p>We are aware that if you are working with EU customers, you need to be able to provide them with the
                        ability to access, update, retrieve and remove personal data. We got you! We've been set up as self
                        service from the start and have always given you access to your data and your customers data. Our
                        customer support team is here for you to answer any questions you might have about working with the
                        API. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>California Residents </h3>The California Consumer Privacy Act (CCPA) requires us to disclose
                        categories of Personal Information we collect and how we use it, the categories of sources from whom we
                        collect Personal Information, and the third parties with whom we share it, which we have explained
                        above. </p>
                    <p>We are also required to communicate information about rights California residents have under California
                        law. You may exercise the following rights: </p>
                    <p>&#9679; Right to Know and Access. You may submit a verifiable request for information regarding the:
                        (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories
                        of Personal Information are collected or used by us; (3) categories of sources from which we
                        collect Personal Information; and (4) specific pieces of Personal Information we have collected
                        about you. </p>
                    <p>&#9679; Right to Equal Service. We will not discriminate against you if you exercise your privacy
                        rights. &#9679; Right to Delete. You may submit a verifiable request to close your account and we
                        will delete Personal </p>
                    <p>Information about you that we have collected. &#9679; Request that a business that sells a
                        consumer's personal data, not sell the consumer's personal data. </p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us. </p>
                    <p>We do not sell the Personal Information of our users. </p>
                    <p>For more information about these rights, please contact us.</p>

                </div>
            </div>
            <div>
                <div>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>California Online Privacy Protection Act (CalOPPA) </h3>CalOPPA requires us to disclose
                        categories of Personal Information we collect and how we use it, the categories of sources from whom we
                        collect Personal Information, and the third parties with whom we share it, which we have explained
                        above. </p>
                    <p>CalOPPA users have the following rights: </p>
                    <p>&#9679; Right to Know and Access. You may submit a verifiable request for information regarding the:
                        (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories
                        of Personal Information are collected or used by us; (3) categories of sources from which we
                        collect Personal Information; and (4) specific pieces of Personal Information we have collected
                        about you. </p>
                    <p>&#9679; Right to Equal Service. We will not discriminate against you if you exercise your privacy
                        rights. &#9679; Right to Delete. You may submit a verifiable request to close your account and we
                        will delete Personal </p>
                    <p>Information about you that we have collected. &#9679; Right to request that a business that sells a
                        consumer's personal data, not sell the consumer's personal </p>
                    <p>data. </p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us. </p>
                    <p>We do not sell the Personal Information of our users. </p>
                    <p>For more information about these rights, please contact us. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Contact Us </h3>Don't hesitate to contact us if you have any questions. </p>
                    <p>&#9679; Via Email: info@petrepublic.ae </p>
                    <p>Powered by TCPDF (www.tcpdf.org)</p>

                </div>
            </div>

            <div>
                <div>
                    <div className={`${styles["main-heading"]}`}>
                        <h2>RETURN &amp; REFUND POLICY</h2>
                         
                        Updated at November 29th, 2023
                    </div>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Definitions and key terms </h3>To help explain things as clearly as possible in this Return &amp;
                        Refund Policy, every time any of these terms are referenced, are strictly defined as: </p>
                    <p>&#9679; Company: when this policy mentions &#8220;Company,&#8221; &#8220;we,&#8221; &#8220;us,&#8221; or
                        &#8220;our,&#8221; it refers to Pet Republic, that is responsible for your information under this
                        Return &amp; Refund Policy. </p>
                    <p>&#9679; Customer: refers to the company, organization or person that signs up to use the Pet Republic
                        Service to manage the relationships with your consumers or service users. </p>
                    <p>&#9679; Device: any internet connected device such as a phone, tablet, computer or any other device that
                        can be used to visit Pet Republic and use the services. </p>
                    <p>&#9679; Service: refers to the service provided by Pet Republic as described in the relative terms (if
                        available) and on this platform. </p>
                    <p>&#9679; Website: Pet Republic&#8217;s site, which can be accessed via this URL:
                        https://www.petrepublic.ae/ &#9679; You: a person or entity that is registered with Pet Republic to
                        use the Services. </p>
                    <p>This Return &amp; Refund Policy was created with Termify. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Return &amp; Refund Policy </h3>Thanks for shopping at Pet Republic. We appreciate the fact that
                        you like to buy the stuff we build. We also want to make sure you have a rewarding experience while
                        you&#8217;re exploring, evaluating, and purchasing our products. </p>
                    <p>As with any shopping experience, there are terms and conditions that apply to transactions at Pet
                        Republic. We&#8217;ll be as brief as our attorneys will allow. The main thing to remember is that
                        by placing an order or making a purchase at Pet Republic, you agree to the terms set forth below
                        along with Pet Republic&#8217;s Privacy Policy. </p>
                    <p>If there&#8217;s something wrong with the product/service you bought, or if you are not happy with it,
                        you have 1 week to issue a refund and return your product/service. </p>
                    <p>If you would like to return a product, the only way would be if you follow the next guidelines: </p>
                    <p>&#9679; The package needs to come back with the original receipt of the purchase. &#9679; The
                        product has to be in the same packaging we sent it. &#9679; The product has to be damage free, if
                        we find any damage on the product we will cancel your refund </p>
                    <p>immediately. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Refunds </h3>We at Pet Republic, commit ourselves to serve our customers with the best products.
                        Every single product</p>

                </div>
            </div>
            <div>
                <div>
                    <p>that you choose is thoroughly inspected, checked for defects and packaged with utmost care. We do this
                        to ensure that you fall in love with our products. </p>
                    <p>Sadly, there are times when we may not have the product(s) that you choose in stock, or may face
                        some issues with our inventory and quality check. In such cases, we may have to cancel your order.
                        You will be intimated about it in advance so that you don't have to worry unnecessarily about your
                        order. If you have purchased via Online payment (not Cash on Delivery), then you will be refunded
                        once our team confirms your request. </p>
                    <p>We carry out thorough quality check before processing the ordered item. We take utmost care while
                        packing the product. At the same time we ensure that the packing is good such that the items
                        won&#8217;t get damaged during transit. Please note that Pet Republic is not liable for damages
                        that are caused to the items during transit or transportation. </p>
                    <p>We will revise your returned product as soon as we receive it and if it follows the guidelines
                        addressed above, we will proceed to issue a refund of your purchase. Your refund may take a couple
                        of days to process but you will be notified when you receive your money. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Shipping </h3>Pet Republic is responsible for return shipping costs. Every returning shipping is
                        paid by Pet Republic, even if the item didn&#8217;t have free shipping. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Your Consent </h3>By using our website, registering an account, or making a purchase, you hereby
                        consent to our Return &amp; Refund Policy and agree to its terms. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Changes To Our Return &amp; Refund Policy </h3>Should we update, amend or make any changes to this
                        document so that they accurately reflect our Service and policies. Unless otherwise required by law,
                        those changes will be prominently posted here. Then, if you continue to use the Service, you will be
                        bound by the updated Return &amp; Refund Policy. If you do not want to agree to this or any updated
                        Return &amp; Refund Policy, you can delete your account by contacting us at [ info@petrepublic.ae]. </p>
                    <p>
                        <h3 className={`${styles["sub-heading"]}`}>Contact Us </h3>If, for any reason, You are not completely satisfied with any good or service that
                        we provide, don't hesitate to contact us and we will discuss any of the issues you are going through
                        with our product. </p>
                    <p>&#9679; Via Email: info@petrepublic.ae </p>
                    <p>Powered by TCPDF (www.tcpdf.org)</p>

                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy;
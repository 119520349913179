import { useEffect, useState } from 'react';

import CancelAppointment from '../Common/CancelAppointment';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import CreateAppointment from '../Common/CreateAppointment';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import PetDetails from '../Common/PetDetails';
import Resechdule from '../Common/Resechdule';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import moment from 'moment';
// Table Imports
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from '../../../store/hooks';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
}

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const AppointmentsTable = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [appointments, setAppointments] = useState([] as any);
  const { user } = useAppSelector((state) => state.user);
  const [checked] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState({});
  const [users, setUsers] = useState([{
    email: "All",
    fullname: "All",
    _id: "All"
  }]);
  const [selectedUser, setSelectedUser] = useState({
    email: "All",
    fullname: "All",
    _id: "All"
  });
  const [selectedUserId, setSelectedUserId] = useState("");

  const [from, setFrom] = useState<Dayjs | null>(null);
  const [to, setTo] = useState<Dayjs | null>(null);

  const [createNewAppointment, setCreateNewAppointment] = useState(false);

  const getAllAppointments = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/appointment/getAllAppointment?page=${page}&limit=10&from=${from?.toISOString() ?? ""}&to=${to?.toISOString() ?? ""}&vet=${selectedUserId ?? ""}`
      );
      console.log("data: ", data);

      setAppointments(data?.data);
      // setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        'Showing' +
        (data?.currentPage * 10 - 9) +
        '-' +
        data?.currentPage * 10 +
        ' of ' +
        data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/dashUser/getAllVets`
      );

      console.log("data: ", data);

      // setUsers(
      //   data?.data?.map((item: any) => {
      //     return {
      // label: `${item.username} (${item.email})`,
      // label: `${item.centerName}`,
      //       id: item._id,
      //     };
      //   })
      // );

      setUsers(prev => [...prev, ...data?.data]);
      // setUsers(data?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  useEffect(() => {
    getAllAppointments();
  }, [page, checked, from, to, selectedUserId]);

  useEffect(() => {
    if (user?.userType === "ADMIN") {
      getAllUsers();
    }
  }, []);

  return (
    <>
      {/* <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" /> */}

      <div className="w-full grid grid-cols-3 gap-4 py-5 my-5">

        {
          user?.userType === "ADMIN" && users?.length &&

          // <h2 className="publicSans leading-8 font-semibold text-blackTheme">
          //   Select User
          // </h2>
          <Autocomplete
            // name="selectedUser"

            // multiple

            value={selectedUser} // need to have its initial value match with any value of users or label. i.e., 'All'
            options={users}
            getOptionLabel={(option) => `${option?.fullname} ${option?._id !== "All" ? `(${option?.email})` : ""}`}
            isOptionEqualToValue={(option, value) => {
              // console.log("Selected option:", option);
              // console.log("Selected value:", value);
              // return option === value; // default
              return option._id === value._id;
            }}
            // size='small'
            sx={styles.root}
            renderInput={(params) => (
              <TextField
                {...params}
                // variant="standard"
                label="Vets"
                placeholder="Select Vet"
              />
            )}

            // renderOption={(props, option) => (
            //   <MenuItem {...props} key={option.id}>
            //     {option.label}
            //   </MenuItem>
            // )}

            onChange={(e, value) => {
              // console.log("event:", e);
              // console.log("Selected User:", value);
              // console.log("Selected User's Id:", value?._id);
              setSelectedUser(value);
              setSelectedUserId(value?._id);
              // setSelectedUserId(value.map((item: any) => item?.id));
            }}
          />
        }
        <DatePicker
          label="From Date"
          value={from}
          onChange={(newValue) => {
            // console.log("From Date:", newValue);
            // console.log("From Date:", newValue?.toISOString());
            setFrom(newValue);
          }}
        />
        <DatePicker
          label="To Date"
          value={to}
          onChange={(newValue) => setTo(newValue)}
        />
      </div>



      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              Appointment Management
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              Appointment Management
            </p>
          </div>
          <div className="flex gap-4">
            <button
              className="border border-[#1F9CB9] bg-[#F0F7FC] rounded-lg py-1 px-3 text-[#1F9CB9] publicSans text-sm font-normal whitespace-nowrap leading-5 "
              onClick={() => {
                setCreateNewAppointment(true);
              }}
            >
              Create New Appointment
            </button>
            {/* <button className="border border-[#1F9CB9] bg-[#F0F7FC] rounded-lg py-1 px-3 text-[#1F9CB9] publicSans text-sm font-normal whitespace-nowrap leading-5 ">
              Appointment History
            </button> */}
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              <Table
                sx={{
                  borderCollapse: 'separate !important',
                  width: '100%',
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>Title</CustomTableHeadCell>
                    <CustomTableHeadCell>Vet Name</CustomTableHeadCell>
                    <CustomTableHeadCell>User Name</CustomTableHeadCell>
                    <CustomTableHeadCell>Booking Made</CustomTableHeadCell>
                    <CustomTableHeadCell>Appointment Date</CustomTableHeadCell>
                    <CustomTableHeadCell>Total Amount (AED)</CustomTableHeadCell>
                    <CustomTableHeadCell>Action</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment: any) => {
                    return (
                      <CustomTableBodyRow key={appointment?._id}>
                        <CustomTableBodyCell>
                          <div className="w-full flex gap-2 items-center">
                            <img
                              src={
                                appointment?.pet?.profilePicture[0]
                                  ? appointment?.pet?.profilePicture[0]?.url
                                  : '/assets/images/pet.jpeg'
                              }
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                            <div className="flex flex-col gap-1">
                              <p className="text-[#093147] publicSans text-sm  font-medium leading-5">
                                {appointment?.pet?.name}
                              </p>
                              <p className="publicSans text-xs font-normal leading-4 tracking-tight">
                                {appointment?.pet?.breed}
                              </p>
                            </div>
                          </div>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {appointment?.vet?.fullname}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {appointment?.user?.fullname}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(appointment?.createdAt).format('DD-MM-YYYY')
                            +
                            ` (${moment(appointment?.createdAt).format('hh:mm A')}) `
                          }
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(appointment?.appointmentTimesamp).format('DD-MM-YYYY')
                            +
                            ` (${appointment.startTime} - ${appointment.endTime}) `
                          }
                        </CustomTableBodyCell>

                        <CustomTableBodyCell>
                          {appointment?.amount}
                          {/* {appointment?.amount_received} */}
                        </CustomTableBodyCell>

                        <CustomTableBodyCell>
                          <div className="w-full flex gap-3 items-center">
                            {/* <button className="text-[#1F9CB9] publicSans text-sm font-medium leading-5">
                            Pet Passport
                          </button> */}
                            <button
                              className="border border-[#11C661] rounded-lg publicSans text-sm py-2 px-3 font-medium leading-5 text-[#11C661]  "
                              onClick={() => {
                                setSelected(appointment);
                                setRescheduleOpen(true);
                              }}
                            >
                              Reschedule
                            </button>
                            <button
                              onClick={() => {
                                setSelected(appointment);
                                setOpenDetails(true);
                              }}
                              className="text-white publicSans text-sm font-normal leading-5 bg-[#1F9CB9] py-2 px-3 rounded-lg "
                            >
                              Details
                            </button>
                            <button
                              onClick={() => {
                                setSelected(appointment);
                                setOpenCancel(true);
                              }}
                              className="text-white publicSans text-sm font-normal leading-5 bg-[#EF8214] py-2 px-3 rounded-lg "
                            >
                              Cancel
                            </button>
                          </div>
                        </CustomTableBodyCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={rescheduleOpen}
        onClose={() => {
          setRescheduleOpen(false);
          getAllAppointments();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Resechdule
          setOpen={(value) => {
            setRescheduleOpen(value);
            if (!value) {
              getAllAppointments();
            }
          }}
          serviceId={
            (selected as any).services ? (selected as any).services[0]._id : ''
          }
          bookingId={(selected as any)._id}
        />
      </Modal>
      <Modal
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CancelAppointment
          setOpen={setOpenCancel}
          selected={selected}
          getAllAppointments={getAllAppointments}
        />
      </Modal>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PetDetails
          setOpen={setOpenDetails}
          existingData={selected}
          setOpenCancel={setOpenCancel}
        />
      </Modal>
      <Modal
        open={createNewAppointment}
        onClose={() => setCreateNewAppointment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateAppointment
          setOpen={(value) => {
            setCreateNewAppointment(value);
            getAllAppointments();
          }}
        />
      </Modal>
    </>
  );
};

export default AppointmentsTable;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import AddAdvertisement from "./AddAdvertisement";
import EditAdvertisement from "./EditAdvertisement";
import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from "../../../utils/axios";
import { useAppSelector } from "../../../store/hooks";
// Table Imports
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import { useNavigate } from "react-router";
import moment from "moment";
import dayjs from "dayjs";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const AdvertisementsTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [userType, setUserType] = useState("");
  const [advertisements, setAdvertisements] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState("");
  const [addAdvertisementModal, setAddAdvertisementModal] = useState(false);
  const [editAdvertisementModal, setEditAdvertisementModal] = useState(false);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState({});
  const { user } = useAppSelector((state) => state?.user);
  const [status, setStatus] = useState("All");

  const getAllAdvertisements = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/advertisements/getAllAdvertisements?page=${page}&limit=10&vet=${user?._id}&status=${status}&search=${searchText}`
      );
      console.log(data);

      setAdvertisements(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        "Showing" +
        (data?.currentPage * 10 - 9) +
        "-" +
        data?.currentPage * 10 +
        " of " +
        data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const handleEnableDisable = async (event, id: any) => {
    console.log(event.target.checked, id);

    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `dashboard/advertisements/updateAdvertisement/${id}`,
        {
          active: event.target.checked,
        }
      );

      toastMessage(
        `Advertisement ${event.target.checked ? "Disabled" : "Enabled"
        } successfully`,
        "success"
      );
      setAdvertisements((prev) =>
        prev.map((advertisement) => {
          if (advertisement._id == id) return data?.data;
          else return advertisement;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const deleteAdvertisement = async (id: any) => {
    try {
      setLoading(true);

      const data = await callAxios(
        "delete",
        `dashboard/advertisements/deleteAdvertisement/${id}`
      );

      // console.log("data: ", data);
      // console.log("data?.response?.status: ", data?.response?.status);
      // console.log("data?.status: ", data?.status);

      // if (data?.response?.status === 404) {

      //   toastMessage(
      //     `${data?.response?.data?.message ?? "Advertisement not found!"}`,
      //     "error"
      //   ); 
      // }

      if (data?.status === 200) {

        toastMessage(
          `Advertisement Deleted Successfully!`,
          "success"
        );

        setAdvertisements((prev) =>
          prev.filter((advertisement) => advertisement._id !== id)
        );
      }

      setLoading(false);

    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }

  }

  useEffect(() => {
    getAllAdvertisements();
  }, [page, status, searchText, userType]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />

      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Advertisements
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Advertisements
          </p>
        </div>
        <div className="w-full flex flex-row justify-between gap-5 bg-white rounded-lg shadow p-4 px-8 ">
          <div className="w-2/3 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className='flex justify-around'>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="p-2 border rounded-full  outline-none px-4 border-gray-500 text-gray-500 text-sm"
            >
              <option value="" className="text-gray-400" disabled>
                Status
              </option>
              <option value="All" className="text-gray-700">
                All
              </option>
              <option value="true" className="text-gray-700">
                Enable
              </option>
              <option value="false" className="text-gray-700">
                Disable
              </option>
            </select>
          </div>
          {
            user?.userType === "VET" &&
            <div className="flex  justify-center items-center">
              <button
                className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"
                onClick={() => setAddAdvertisementModal(true)}
              >
                Add Advertisements
              </button>
            </div>
          }
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            <Table
              sx={{
                borderCollapse: "separate !important",
                width: "100%",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Description</CustomTableHeadCell>
                  <CustomTableHeadCell>Start Date</CustomTableHeadCell>
                  <CustomTableHeadCell>End Date</CustomTableHeadCell>
                  <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell>
                  <CustomTableHeadCell>
                    <div className="text-center">Action</div>
                  </CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {advertisements.map((advertisement: any) => {
                  return (
                    <CustomTableBodyRow key={advertisement._id}>
                      <CustomTableBodyCell>{advertisement.name}</CustomTableBodyCell>
                      <CustomTableBodyCell className="w-36">
                        {advertisement.description.length > 130 ? advertisement.description.substring(0, 130) + "..." : advertisement.description}
                      </CustomTableBodyCell>
                      {/* <CustomTableBodyCell>
                        {Date.parse(advertisement.startDate) ? moment(advertisement.startDate).format('DD-MM-YYYY hh:mm A') : ''}
                      </CustomTableBodyCell> */}
                      <CustomTableBodyCell>
                        {/* {Date.parse(advertisement.startDate) ? moment(advertisement.startDate).format('DD-MM-YYYY') : ''} */}
                        {dayjs(advertisement.startDate).isValid() ? dayjs(advertisement.startDate).format('DD-MM-YYYY') : ''}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {dayjs(advertisement.endDate).isValid() ? dayjs(advertisement.endDate).format('DD-MM-YYYY') : ''}
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className="flex justify-center">
                          <Switch
                            checked={advertisement?.active}
                            onChange={(e) => handleEnableDisable(e, advertisement?._id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="secondary"
                          />
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <div className="flex gap-3 justify-center">
                          <button
                            onClick={() => {
                              // console.log("advertisement.startDate: ", advertisement.startDate);
                              // console.log("dayjs(advertisement.startDate): ", dayjs(advertisement.startDate));

                              setSelectedAdvertisement({ ...advertisement, startDate: dayjs(advertisement.startDate), endDate: dayjs(advertisement.endDate) });
                              setEditAdvertisementModal(true);
                            }}
                            className="text-white publicSans text-sm font-medium leading-5 bg-green-600 py-1 px-2 rounded "
                          >
                            Edit
                          </button>
                          {/* <button
                            className="text-white publicSans text-sm font-medium leading-5 bg-red-600 py-1 px-2 rounded "
                            onClick={() => { deleteAdvertisement(advertisement?._id) }}
                          >
                            Delete
                          </button> */}
                        </div>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
        <Modal
          open={addAdvertisementModal}
          onClose={() => setAddAdvertisementModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <AddAdvertisement
            setOpen={setAddAdvertisementModal}
            setAdvertisements={setAdvertisements}
          />
        </Modal>
        <Modal
          open={editAdvertisementModal}
          onClose={() => setEditAdvertisementModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <EditAdvertisement
            setOpen={setEditAdvertisementModal}
            setAdvertisements={setAdvertisements}
            selectedAdvertisement={selectedAdvertisement}
            setSelectedAdvertisement={setSelectedAdvertisement}
          />
        </Modal>
      </div>
    </>
  );
};

export default AdvertisementsTable;
